import React from "react";
import { type MeetingUserType } from "./track-tile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/pro-light-svg-icons";
import { UserImage } from "@hiyllo/omni-images/main";
import { GradientLoop } from "./gradient-loop";
import { useTheme } from "@hiyllo/ux/theme";

export const NoVideoPlaceholder = React.memo(
  function NoVideoPlaceholder(props: { user: MeetingUserType }): JSX.Element {
    const $theme = useTheme();

    return (
      <GradientLoop
        outerStyle={{
          height: "100%",
          width: "100%",
        }}
        innerStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 100,
        }}
        gradient={[$theme.background1 ?? "", $theme.background3 ?? ""]}
        angle="45deg"
      >
        {props.user.type === "guest" ? (
          <FontAwesomeIcon icon={faUserCircle} />
        ) : (
          <UserImage userId={props.user.userId} width={100} />
        )}
      </GradientLoop>
    );
  },
);
