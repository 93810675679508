import { styled } from "@hiyllo/ux/styled";
import { motion } from "framer-motion";

export const AlertsPillContainer = styled<
  "div",
  React.ComponentProps<typeof motion.div> & { isActive: boolean }
>(motion.div, ({ $theme, isActive }) => ({
  userSelect: "none",
  ...(isActive
    ? {
      background: $theme.buttonBackground,
      color: $theme.buttonForeground,
    }
    : {
      background: $theme.background3,
      color: $theme.foreground,
    }),
  height: 33.5 - 15,
  paddingTop: 7.5,
  paddingBottom: 7.5,
  borderTopLeftRadius: 15,
  borderBottomLeftRadius: 15,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: 50,
}));
