/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TaskPriorityV3 } from "../../types/tasks/tasks-organization";

export type ParamsType = {
  priority: TaskPriorityV3;
  taskUUID: string;
};
export type ResponseType = void;
export const Endpoint = "tasks/update-task-priority";
export const Method = "POST";

export type Plug = {
  params: ParamsType,
  response: ResponseType,
  method: typeof Method,
  endpoint: typeof Endpoint,
};

export const RateLimitingConfig = {
  calls: 1,
  per: 500,
};export const paramsSchema = {"type":"object","properties":{"priority":{"$ref":"#/definitions/TaskPriorityV3"},"taskUUID":{"type":"string"}},"required":["priority","taskUUID"],"definitions":{"TaskPriorityV3":{"type":"object","properties":{"order":{"type":"number"},"label":{"type":"string"},"color":{"$ref":"#/definitions/TaskColorsEnum"},"projectUUID":{"description":"Project this priority is for (null for global, undefined for Hiyllo defaults)","type":["null","string"]},"default":{"type":"boolean"}},"required":["color","label","order"]},"TaskColorsEnum":{"enum":["amber","aqua","blue","gray","green","red"],"type":"string"}},"$schema":"http://json-schema.org/draft-07/schema#"}