import { Button } from "@hiyllo/ux/button";
import { KeyCombination, KeyCombinationHint, useHotkey } from "@hiyllo/ux/hotkeys";
import { Input, useInputControl } from "@hiyllo/ux/input";
import { Row } from "@hiyllo/ux/structure";
import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { Editor } from "slate";
import { useEscapeHatch } from "@hiyllo/ux/escape-hatch";
import { findAndReplace } from "../actions/find-and-replace";

const PanelContainer = styled("div", ({ $theme }) => ({
  backgroundColor: $theme.background3,
  padding: 10,
  borderRadius: 10,
  position: 'absolute',
  top: 12.5,
  right: 12.5,
  zIndex: 100,
  width: 300,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 0px 10px 10px rgba(0, 0, 0, 0.1)',
  gap: 10,
}));

const findCombination: KeyCombination = ["meta", "Enter"];
// @ts-expect-error ---
const replaceCombination: KeyCombination = ["meta", "alt", "Enter"];

export const FindAndReplacePanel = React.memo(function FindAndReplacePanel(props: {
  editor: Editor;
  onClose: () => void;
}): JSX.Element {
  const findIC = useInputControl({});
  const replaceIC = useInputControl({});

  const find = React.useCallback(() => {
    findAndReplace(props.editor, findIC.valueRef.current);
  }, []);
  useHotkey(findCombination, find);

  const replace = React.useCallback(() => {
    findAndReplace(props.editor, findIC.valueRef.current, replaceIC.valueRef.current);
  }, []);
  useHotkey(replaceCombination, replace);

  useEscapeHatch(props.onClose);

  React.useEffect(() => {
    findAndReplace(props.editor, "");
  }, []);

  return (
    <PanelContainer>
      <Row style={{ gap: 2.5 }}>
        <KeyCombinationHint combination={["ESC"]} /> to close
      </Row>

      <Input
        placeholder="Find..."
        fullWidth
        autoFocus
        {...findIC.inputProps}
      />

      <Input
        placeholder="Replace..."
        fullWidth
        {...replaceIC.inputProps}
      />

      <Row>
        <Button
          label={
            <>
              Find <KeyCombinationHint combination={findCombination} />
            </>
          }
          onClick={find}
        />
        <Button
          label={
            <>
              Replace <KeyCombinationHint combination={replaceCombination} />
            </>
          }
          onClick={replace}
        />
      </Row>
    </PanelContainer>
  );
});