import React from "react";

export function useWorkerResultWithWorker<T, I>(
  worker: Worker,
  input: I,
): [T | null, boolean] {
  const [result, setResult] = React.useState<T | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    console.debug("[Platform/Worker] Executing...");

    worker.postMessage(input);
    worker.onmessage = (event) => {
      setResult(event.data);
      setLoading(false);
    };
    worker.onerror = (event) => {
      setLoading(false);
      console.error('###', event);
    };
  }, [worker, input]);

  return [result, loading];
}

export function useWorkerResult<I, O>(path: URL, input: I): O | null {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const worker = React.useMemo(() => new Worker(path), [path.toString()]);

  return useWorkerResultWithWorker<O, I>(worker, input)[0];
}