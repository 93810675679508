import React from "react";
import * as RetrieveDocumentBP from "../../../blueprints/stuff/retrieve-document";
import * as SaveDocumentBP from "../../../blueprints/stuff/save-document";
import * as DocsEditingSpec from "../../../blueprints/docs/docs-editing-pubsub-spec";
import * as GenerateTextBP from "../../../blueprints/docs/generate-text";

import { seamlessClient } from "../../../seamless-client";
import { usePath } from "@hiyllo/omni-router";
import { type StuffEDataParamsType } from "../../../types/navigation/edata";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { useDebounce } from "@hiyllo/ux/use-debounce";
import { Tenant } from "../../../platform/tenancy";
import { useSelf } from "@hiyllo/omni-continuity/main";
import "@hiyllo/editor/src/editor.css";
import { TabDetails } from "../../tokyo/tabbing/tabs-provider";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import { Editor, EditorRef } from "./v2/editor-v2";
import { BaseOperation, Selection, Editor as SlateEditor, Transforms } from "slate";
import { getHTMLPreview } from "./v2/serialize-html";
import { DocumentContentsV2 } from "../../../types/stuff/document";
import { UseMoopsyQueryRetVal } from "@moopsyjs/react/main";
import { useUploadFile } from "@hiyllo/omni-user-files/main";
import { Command } from "./v2/components/command-palette";
import { NymblIcon } from "@hiyllo/icons/main";
import { useShowDialog } from "@hiyllo/ux/dialogs";
import { ReactEditor } from "slate-react";
import { Electron } from "../../../platform/electron";
import { CaretElem } from "./caret-elem";

function useDocsCommands(): Command[] {
  const showDialog = useShowDialog();
  const generateMutation = seamlessClient.useMutation<GenerateTextBP.Plug>(GenerateTextBP);

  return [{
    icon: NymblIcon,
    label: "Generate Text",
    action: (editor) => {
      const previousSelection = editor.selection;

      void showDialog({
        title: "Generate Text",
        message: "Enter a prompt to generate text to be inserted into the editor. The contents of your document won't be shared",
        requireValue: true,
        onSubmit: async (value) => {
          const res = await generateMutation.call({
            generate: value
          });

          ReactEditor.focus(editor);
          editor.selection = previousSelection;

          res.text.onData((data) => {
            for (const text of data) {
              SlateEditor.insertText(editor, text);
            }

            const end = editor.selection && SlateEditor.end(editor, editor.selection);
            if (end) {
              Transforms.select(editor, end);
            }
          });
        }
      });
    }
  }];
}

export function useMoopsyYJS() {

}

export const DocumentViewV2 = React.memo(function DocumentViewV2(props: {
  retrieveDocumentQuery: UseMoopsyQueryRetVal<RetrieveDocumentBP.Plug>;
  contents: DocumentContentsV2;
}): JSX.Element {
  const { uuid } = usePath().params as StuffEDataParamsType;

  if (uuid == null) {
    throw new Error("uuid is null");
  }

  const retrieveDocumentQuery =
    seamlessClient.useQuery<RetrieveDocumentBP.Plug>(
      RetrieveDocumentBP,
      {
        uuid,
      },
    );
  const saveDocumentMutation =
    seamlessClient.useMutation<SaveDocumentBP.Plug>(SaveDocumentBP);

  const saveDebounce = useDebounce(750, "overwrite");
  const contentsRef = React.useRef<DocumentContentsV2 | null>(null);
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);
  const self = useSelf();
  const editorId = React.useRef(Math.random().toString(36).substring(7));
  const editorRef = React.useRef<EditorRef>(null);
  const applyingRemoteChangesRef = React.useRef<boolean>(false);



  const psTopic = React.useRef(`[${Tenant}]docs-editing/${uuid}`).current;
  const DocsEditingPubsub = seamlessClient.usePublishToTopic(DocsEditingSpec);
  const uploadImage = useUploadFile();

  const [otherUsersSelections, setOtherUsersSelections] = React.useState({});

  // Map to store user colors
  const userColors = React.useRef<Record<string, string>>({});

  const getUserColor = (editorId: string) => {
    if (!userColors.current[editorId]) {
      // Generate a random color
      const color = '#' + Math.floor(Math.random() * 16777215).toString(16);
      userColors.current[editorId] = color;
    }
    return userColors.current[editorId];
  };

  const onDocsEditMessage = React.useCallback(
    (data: DocsEditingSpec.Typings["MessageType"]) => {
      if (data.editorId === editorId.current) {
        return;
      }

      const editor = editorRef.current?.editor;

      if (data.delta != null) {
        editor?.withoutNormalizing(() => {
          applyingRemoteChangesRef.current = true;
          for (const op of data.delta) {
            if (op.editorId !== editorId.current) {
              if (op.type === "set_selection") return;
              console.log('[DocumentViewV2] applying', op);
              editor?.apply(op);
            }
          }
          applyingRemoteChangesRef.current = false;
        });
      }

      if (data.range != null && data.editorId !== editorId.current) {
        setOtherUsersSelections((prev) => ({
          ...prev,
          [data.editorId]: {
            color: getUserColor(data.editorId),
            selection: data.range,
            userId: data.userId,
            timestamp: Date.now(),
          },
        }));
      }
      // TODO: Cursor "#1976d2"
    },
    [],
  );

  const onSelectionChanged = React.useCallback((selection: Selection) => {
    if (!applyingRemoteChangesRef.current) {
      DocsEditingPubsub.publish(psTopic, {
        editorId: editorId.current,
        userId: self.userId,
        delta: null,
        range: selection,
      });
    }
  }, [DocsEditingPubsub, psTopic, self.profile?.name, self.userId]);

  const onContentsChanged = React.useCallback(
    (contents: DocumentContentsV2, operations: BaseOperation[]) => {


      setUnsavedChanges(true);
      contentsRef.current = contents;

      if (!applyingRemoteChangesRef.current) {
        DocsEditingPubsub.publish(psTopic, {
          editorId: editorId.current,
          userId: self.userId,
          // @ts-expect-error ---
          delta: operations.filter(o => o.editorId == null).map(o => {
            // @ts-expect-error ---
            o.editorId = editorId.current;
            return o;
          }),
          range: editorRef.current?.editor?.selection
        });
      }

      saveDebounce.debounce(() => {
        if (contentsRef.current != null) {
          const contents = contentsRef.current;

          console.log('>>> saving', {
            uuid,
            contents,
            contentPreview:
              getHTMLPreview(contents) ??
              "<div></div>",
          });

          void saveDocumentMutation
            .call({
              uuid,
              contents,
              contentPreview:
                getHTMLPreview(contents) ??
                "<div></div>",
            })
            .then(() => {
              setUnsavedChanges(contents !== contentsRef.current);
            });
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saveDebounce, saveDocumentMutation, uuid],
  );

  seamlessClient.useSubscribeToTopic(
    DocsEditingSpec,
    psTopic,
    onDocsEditMessage,
  );

  const commands = useDocsCommands();

  if (retrieveDocumentQuery.data == null) {
    return <LoadingSpinnerFullView />;
  }

  return (
    <TabDetails label={retrieveDocumentQuery.data.document.name} icon={faFile}>
      <Editor
        ref={editorRef}
        onValueChanged={onContentsChanged}
        onDownloadablePDF={Electron.isElectron ? (blob) => {
          void Electron.downloadBlob(blob, "document.pdf");
        } : null}
        onSelectionChanged={onSelectionChanged}
        isSaving={saveDocumentMutation.isLoading || unsavedChanges}
        initialContents={retrieveDocumentQuery.data.document.contents.v2 === true ? retrieveDocumentQuery.data.document.contents : null}
        onImageUploaded={blob => {
          return uploadImage(new File([blob], "image"));
        }}
        customCommands={commands}
        selections={otherUsersSelections}
        enablePDFDownload
        CaretElem={CaretElem}
      />
    </TabDetails>
  );
});
