/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { MailMessageAttachment } from "../../types/mail/message/message";
import { DescendantType } from "../../types/stuff/document";

export type ParamsType = {
  /**
   * If specified, upserts the messageUUID with the updates
   * params.
   */
  messageUUID: string | null;
  email: {
    to: string[];
    cc: string[];
    bcc: string[];
    subject: string;
    html: string;
    text: string | null;
    inReplyTo: string | null;
    attachments?: MailMessageAttachment[];
  };
  draftContents?: DescendantType[] | null;
  mailboxUUID?: string | null;
};
export type ResponseType = {
  messageUUID: string;
};
export const Endpoint = "mail/save-draft";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 4,
  per: 1000,
};
export const paramsSchema = {"type":"object","properties":{"messageUUID":{"description":"If specified, upserts the messageUUID with the updates\nparams.","type":["null","string"]},"email":{"type":"object","properties":{"to":{"type":"array","items":{"type":"string"}},"cc":{"type":"array","items":{"type":"string"}},"bcc":{"type":"array","items":{"type":"string"}},"subject":{"type":"string"},"html":{"type":"string"},"text":{"type":["null","string"]},"inReplyTo":{"type":["null","string"]},"attachments":{"type":"array","items":{"$ref":"#/definitions/MailMessageAttachment"}}},"required":["bcc","cc","html","inReplyTo","subject","text","to"]},"draftContents":{"anyOf":[{"type":"array","items":{"anyOf":[{"$ref":"#/definitions/ParagraphDescendantType"},{"$ref":"#/definitions/HiylloImageDescendantType"}]}},{"type":"null"}]},"mailboxUUID":{"type":["null","string"]}},"required":["email","messageUUID"],"definitions":{"MailMessageAttachment":{"type":"object","properties":{"fsId":{"type":"string"},"filename":{"type":"string"},"size":{"type":"number"}},"required":["filename","fsId","size"]},"ParagraphDescendantType":{"type":"object","properties":{"type":{"type":"string","enum":["paragraph"]},"children":{"type":"array","items":{"type":"object","properties":{"text":{"type":"string"}},"required":["text"]}}},"required":["children","type"]},"HiylloImageDescendantType":{"type":"object","properties":{"type":{"type":"string","enum":["hiylloImage"]},"src":{"type":"string"},"fsId":{"type":"string"}},"required":["fsId","src","type"]}},"$schema":"http://json-schema.org/draft-07/schema#"}