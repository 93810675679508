import {
  TrackRefContext,
  type UseParticipantsOptions,
} from "@livekit/components-react";
import React from "react";
import type { TrackReferenceOrPlaceholder } from "@livekit/components-core";
import { styled } from "@hiyllo/ux/styled";
import { getTrackReferenceId } from '@livekit/components-core';
import { TrackTile } from "./track-tile";
import { MeetingCanvasDimensionsCtx } from "../../providers/meeting-canvas";

export interface GridLayoutProps
  extends React.HTMLAttributes<HTMLDivElement>,
  Pick<UseParticipantsOptions, "updateOnlyOn"> {
  children: React.ReactNode;
  tracks: TrackReferenceOrPlaceholder[];
}

const TrackCountContext = React.createContext<number>(0);

const calculateDimensions = (trackCount: number, canvasWidth: number, canvasHeight: number) => {
  const aspectRatio = 16 / 9;
  let width, height;

  if (trackCount === 1) {
    width = canvasWidth;
    height = canvasWidth / aspectRatio;
    if (height > canvasHeight) {
      height = canvasHeight;
      width = height * aspectRatio;
    }
  } else if (trackCount === 2) {
    width = canvasWidth / 2;
    height = width / aspectRatio;
  } else if (trackCount <= 4) {
    width = canvasWidth / 2;
    height = width / aspectRatio;
  } else if (trackCount <= 6) {
    width = canvasWidth / 3;
    height = width / aspectRatio;
  } else if (trackCount <= 9) {
    width = canvasWidth / 3;
    height = width / aspectRatio;
  } else if (trackCount <= 12) {
    width = canvasWidth / 4;
    height = width / aspectRatio;
  } else {
    width = canvasWidth / 4;
    height = width / aspectRatio;
  }

  // Ensure the height does not exceed canvas height
  if (height * Math.ceil(trackCount / Math.floor(canvasWidth / width)) > canvasHeight) {
    height = canvasHeight / Math.ceil(trackCount / Math.floor(canvasWidth / width));
    width = height * aspectRatio;
  }

  return { width, height };
};

export function useTrackGridElementStyles(): React.CSSProperties {
  const trackCount = React.useContext(TrackCountContext);
  const canvasDimensions = React.useContext(MeetingCanvasDimensionsCtx);

  console.log('>>> canvasDimensions', canvasDimensions);

  const { width, height } = React.useMemo(() => calculateDimensions(trackCount, canvasDimensions.width, canvasDimensions.height), [canvasDimensions.height, canvasDimensions.width, trackCount]);

  return {
    width: `${width}px`,
    height: `${height}px`,
  };
}

const GridContainer = styled("div", {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
});

export function GridLayout({ tracks, ...props }: GridLayoutProps): JSX.Element {
  return (
    <TrackCountContext.Provider value={tracks.length}>
      <GridContainer>
        {props.children}
      </GridContainer>
    </TrackCountContext.Provider>
  );
}

export const GridTrackRenderer = React.memo(function GridTrackRenderer(props: {
  tracks: TrackReferenceOrPlaceholder[];
}): JSX.Element {
  const tracks = React.useMemo(() => {
    return props.tracks.filter((track) => track.publication?.trackName !== "screenshare/background");
  }, [props.tracks]);

  return (
    <GridLayout tracks={tracks}>
      {tracks.map((track) => (
        <TrackRefContext.Provider
          value={track}
          key={getTrackReferenceId(track)}
        >
          <TrackGridElement key={getTrackReferenceId(track)}>
            <TrackTile track={track} />
          </TrackGridElement>
        </TrackRefContext.Provider>
      ))}
    </GridLayout>
  );
});

export const TrackGridElement = React.memo(function TrackGridElement(
  props: React.PropsWithChildren,
): JSX.Element {
  const styles = useTrackGridElementStyles();
  return <div style={styles}>{props.children}</div>;
});
