import React from "react";
import { Sidebar } from "@hiyllo/ux/tokyo";
import { usePath } from "@hiyllo/omni-router";

import { ICDashboard } from "./views/ic-dashboard";
import { LeftSidebar } from "../tokyo/components/left-sidebar";
import { MainContent } from "../tokyo/components/main-content";
import { TalentEDataParamsType } from "../../types/navigation/edata";
import { AdminDashboard } from "./views/admin-dashboard";
import { useNavigateTo, useSelf } from "@hiyllo/omni-continuity/main";
import { faBank } from "@fortawesome/pro-light-svg-icons";
import { Features } from "../../types/navigation/features";

export const TalentFeature = React.memo(function TalentFeature(): JSX.Element {
  const params = usePath().params as TalentEDataParamsType;
  const self = useSelf();
  const goToAdminDashboard = useNavigateTo({
    feature: Features.talent,
    params: { view: 'admin-dashboard' }
  });

  return (
    <>
      <LeftSidebar>
        <Sidebar.Header label="Pay" />

        {self.isAdmin === true ?
          <Sidebar.Button
            icon={{ fa: faBank }}
            label="Admin Dashboard"
            onClick={goToAdminDashboard}
            isActive={params.view === 'admin-dashboard'}
          />
          : null}
      </LeftSidebar>
      <MainContent noPadding>
        {params.view === 'admin-dashboard' ?
          <AdminDashboard />
          :
          <ICDashboard />
        }
      </MainContent>
    </>
  );
});