import React from "react";
import { PDFViewer } from "./pdf-viewer";
import { styled } from "@hiyllo/ux/styled";
import { openWindow } from "./open-window";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { faArrowDownToLine, faEmptySet } from "@fortawesome/pro-light-svg-icons";
import { Button, ButtonVariant } from "@hiyllo/ux/button";
import { LoadingSpinnerView } from "@hiyllo/ux/loading-spinner";

type GalleryItem = {
  src: string;
};

const CenteredFull = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
});

function determineExtension(urlIn: string): string {
  const url = new URL(urlIn);
  const fileName = url.pathname.split("/").pop();
  const extension = fileName?.split(".").pop();
  return (extension ?? "").toLowerCase();
}

function determineTypeFromExtension(extension: string): string | null {
  switch (extension) {
    case "txt":
      return "text/;";
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "webp":
    case "svg":
    case "bmp":
      return "image/";
    case "mp4":
    case "webm":
    case "ogg":
    case "avi":
    case "mov":
    case "wmv":
    case "flv":
    case "mkv":
      return "video/";
    case "mp3":
    case "wav":
    case "ogg":
    case "flac":
    case "aac":
    case "wma":
    case "m4a":
      return "audio/";
    case "pdf":
      return "application/pdf";
    default:
      return null;
  }
}

const HeaderBar = styled<"div">("div", ({ $theme }) => ({
  // background: $theme.background3,
  height: 60,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexShrink: 0,
  paddingLeft: 130,
  paddingRight: 10,
  position: "absolute",
  top: 0,
  left: 0,
  width: "calc(100% - 140px)",
}));

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
});

const TextFileViewer = React.memo(function TextFileViewer(props: {
  src: string;
}): JSX.Element {
  const [contents, setContents] = React.useState<string | null>(null);

  React.useEffect(() => {
    fetch(props.src)
      .then((response) => response.text())
      .then((text) => {
        setContents(text);
      })
      .catch(err => {
        console.error(err);
        setContents("Failed to load file");
      });
  }, [props.src]);

  if (contents === null) {
    return <LoadingSpinnerView />;
  }

  return (
    <div style={{ backgroundColor: "black", height: "calc(100% - 20px)", width: "calc(100% - 20px)", padding: 10, borderRadius: 10 }}>
      <div style={{ whiteSpace: "pre-wrap", fontFamily: "monospace" }}>
        {contents}
      </div>
    </div>
  );
});

const GalleryPanel = React.memo(function GalleryPanel(props: {
  item: GalleryItem;
}): JSX.Element {
  const extension = determineExtension(props.item.src);
  const type = React.useMemo(() => {
    return determineTypeFromExtension(extension) ?? "";
  }, [extension]);

  return (
    <CenteredFull>
      {type.startsWith("text/") ?
        <TextFileViewer
          src={props.item.src}
        />
        : type.startsWith("image/") ? (
          <img
            src={props.item.src}
            alt="Asset"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              objectPosition: "center",
            }}
          />
        ) : type.startsWith("video/") ? (
          <video
            src={props.item.src}
            controls
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              objectPosition: "center",
            }}
          />
        ) : type.startsWith("audio/") ? (
          <audio
            src={props.item.src}
            controls
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              objectPosition: "center",
            }}
          />
        ) : type === "application/pdf" ?
          <PDFViewer
            src={props.item.src}
          />
          : (
            <EmptySplash icon={faEmptySet} label="No preview available" hint={`"${type === "" ? extension : type}" is not supported`} />
          )}
    </CenteredFull>
  );
});

export const FileGallery = React.memo(function FileGallery(props: {
  files: [GalleryItem];
  showDownload?: boolean;
  inModal?: boolean;
}): JSX.Element {
  const download = React.useCallback(() => {
    openWindow(props.files[0].src);
  }, [props.files]);

  return (
    <Container>
      {props.showDownload === true ?
        <HeaderBar>
          <div style={{ flexGrow: 1 }} />
          <CircleButton icon={faArrowDownToLine} onClick={download} size={30} square />
        </HeaderBar>
        : null}
      <div style={{ height: 0, flexGrow: 1, overflow: "auto", display: "flex", flexDirection: "column" }}>
        {props.inModal === true ? <div style={{ height: 40 }} /> : null}
        <div style={{ height: 0, flexGrow: 1 }}>
          <div style={{ padding: 20, height: "calc(100% - 40px)" }}>
            <GalleryPanel item={props.files[0]} />
          </div>
        </div>
      </div>
    </Container>
  );
});