import React from "react";
import { ConfigContext } from "../platform/config/config-context";
import { type OmniConfigType } from "../types/config/omni-config";
import { seamlessAuth, seamlessClient } from "../seamless-client";

import * as GetConfigBlueprint from "../blueprints/config/get-config";
import { LoadingPage } from "@hiyllo/ux/standard-loader";
import { useCache } from "@hiyllo/omni-continuity/main";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";

export const OfflineConfigProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  console.log("<OfflineConfigProvider>");
  // @ts-expect-error window._omniConfigInit
  const data = window._omniConfigInit as OmniConfigType | void;

  React.useEffect(() => {
    document.title = data?.platformName ?? "";
  }, [data?.platformName]);

  if (data == null) {
    return <>{children}</>;
  }

  const customFonts = data.ux.customFonts ?? [];

  return (
    <ConfigContext.Provider
      value={{
        data,
        // @ts-expect-error query=null
        query: null,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

const CACHE_KEY = "@config/live";

export const OnlineConfigProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  console.log("<OnlineConfigProvider>");

  const cache = useCache();
  const [cachedLiveConfig, setCachedLiveConfig] = React.useState<OmniConfigType | null>(null);
  const usp = new window.URLSearchParams(window.location.search);
  const configQuery = seamlessClient.useQuery<GetConfigBlueprint.Plug>(
    GetConfigBlueprint,
    { userAgent: window.navigator.userAgent },
  );

  React.useEffect(() => {
    if (configQuery.data != null) {
      void cache.set<OmniConfigType>(CACHE_KEY, configQuery.data);
    }
  }, [cache, configQuery.data]);

  React.useEffect(() => {
    seamlessAuth.on.authSuccess(
      () => {
        void configQuery.refresh({ subtle: true });
      },
    );

    void cache.get<OmniConfigType>(CACHE_KEY).then(setCachedLiveConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // @ts-expect-error window._omniConfigInit
  const initConfig = cachedLiveConfig ?? (window._omniConfigInit as OmniConfigType | void);
  const data: OmniConfigType =
    usp.get("moverrideconfig") != null
      ? JSON.parse(decodeURIComponent(usp.get("moverrideconfig") as string))
      : configQuery.data ?? initConfig;

  React.useEffect(() => {
    document.title = data?.platformName ?? "";
  }, [data?.platformName]);

  if (configQuery.error != null && initConfig == null) {
    return (
      <EmptySplash
        icon={faExclamationTriangle}
        label="Failed to load config"
        hint={configQuery.error.message}
      />
    );
  }

  if (
    (configQuery.data == null || configQuery.isLoading) &&
    initConfig == null
  ) {
    return <LoadingPage />;
  }

  const customFonts = data.ux.customFonts ?? [];

  return (
    <ConfigContext.Provider
      value={{
        data,
        query: configQuery,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
