import moment from "moment";
import React from "react";
import { MeetingTimer } from "../views/components/meeting-timer";
import { useTick } from "../../stuff/documents/v2/editor-v2";

export function useGreeting(name: string): string | JSX.Element {
  useTick();
  const date = moment().format("MMMDD");

  return React.useMemo(() => {
    const hour = moment().hour();

    /** Holiday Special Messages */
    if (date === "Dec25") {
      return `Merry Christmas${name}! 🎄`;
    }
    if (date === "Dec31") {
      return (
        <span>
          <MeetingTimer startDate={moment().add(1, 'year').startOf("year").toDate()} /> till {moment().add(1, 'year').format("YYYY")}
        </span>
      );
    }
    if (date === "Jan1") {
      return `Happy New Year${name}! 🎆`;
    }

    /** Standard Messaging */
    if (hour < 5) {
      return `Burning the midnight oil${name}?`;
    } else if (hour >= 5 && hour < 9) {
      return `Looks like you're an early bird${name}`;
    } else if (hour >= 9 && hour < 12) {
      return `Good morning${name}`;
    } else if (hour >= 12 && hour < 18) {
      return `Good afternoon${name}`;
    } else {
      return `Good evening${name}`;
    }
  }, [date, name]);
}
