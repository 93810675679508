import React from "react";
import { getSelectionRects, useTick } from "./v2/editor-v2";
import { useTheme } from "@hiyllo/ux/theme";
import { useLookupByUserId } from "@hiyllo/omni-continuity/main";
import { UserImage } from "@hiyllo/omni-images/main";

export const CaretElem = React.memo(function CaretElem({ editor, editorId, selection, userId, color }: any): JSX.Element {
  useTick();

  const $theme = useTheme();
  const rects = getSelectionRects(editor, selection);
  const user = useLookupByUserId(userId);
  const rect = rects[0];

  return (
    <>
      {rect != null ? <div
        key={`${editorId}-C`}
        className="caret"
        style={{
          position: 'absolute',
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
          backgroundColor: color,
          pointerEvents: 'none',
        }}
      >
        <div style={{ position: "relative" }}>
          <div style={{ fontSize: 12.5, padding: 2.5, borderRadius: 2.5, borderBottomLeftRadius: 0, position: "absolute", bottom: rect.height, left: 0, backgroundColor: $theme.colorSubtleAccent, display: "flex", flexDirection: "row", gap: 2.5, alignItems: "center", whiteSpace: "nowrap" }}>
            <UserImage
              userId={userId}
              width={15}
            />
            {user.data?.name}
          </div>
          <div style={{
            backgroundColor: $theme.colorSubtleAccent,
            width: 2,
            height: rect.height
          }} />
        </div>
      </div> : null}
      {rects.map((rect, index) => (
        <div
          key={`${editorId}-${index}`}
          className="caret"
          style={{
            position: 'absolute',
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX,
            height: rect.height,
            width: rect.width,
            backgroundColor: $theme.colorSubtleAccent,
            opacity: 0.5,
            pointerEvents: 'none',
          }}
        />
      ))}
    </>
  );
});