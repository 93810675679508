/* eslint-disable @typescript-eslint/ban-types */
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faSparkles } from "@fortawesome/pro-light-svg-icons";
import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { useWhoChanged } from "../../../platform/debug";
import { IdleStateProvider } from "@hiyllo/idle";


interface Tab {
  uuid: string;
}

const initialTabs: Tab[] = [{
  uuid: crypto.randomUUID(),
}];

export const TabsContext = React.createContext<{
  activeTab: string;
  setActiveTab: (uuid: string) => void;
  tabs: Tab[],
  setTabs: React.Dispatch<React.SetStateAction<Tab[]>>;
}>({
  tabs: initialTabs,
  setTabs: () => { },
  activeTab: initialTabs[0].uuid,
  setActiveTab: () => { }
});

const OnNewTabCtx = React.createContext<() => void>(() => { });

export function useOnNewTab(): () => void {
  return React.useContext(OnNewTabCtx);
}

const CurrentTabContext = React.createContext<string | null>(null);

export function useCurrentTab(): string {
  const current = React.useContext(CurrentTabContext);

  if (current == null) {
    throw new Error("CurrentTabContext is null");
  }

  return current;
}

const TabSandbox = styled<"div", { active: boolean }>("div", ({ active }) => (
  active ? {
    height: '100%'
  } : {
    display: "none"
  }
));

export const TabArea = React.memo(function TabArea(props: React.PropsWithChildren<{ tab: Tab, hasActivity: boolean; }>): JSX.Element {
  const tabbing = React.useContext(TabsContext);
  const active = tabbing.activeTab === props.tab.uuid;

  return (
    <TabSandbox active={active}>
      <CurrentTabContext.Provider value={props.tab.uuid}>
        <IdleStateProvider active={active && props.hasActivity}>
          {props.children}
        </IdleStateProvider>
      </CurrentTabContext.Provider>
    </TabSandbox>
  );
});

interface TabDetails {
  uuid: string;
  label: string;
  icon: IconDefinition | JSX.Element;
}

const SetTabsCtx = React.createContext<React.Dispatch<React.SetStateAction<Tab[]>> | null>(null);
const SetTabDetailsCtx = React.createContext<React.Dispatch<React.SetStateAction<TabDetails[]>> | null>(null);
export const TabDetailsCtx = React.createContext<TabDetails[]>([]);

export const TabDetails = React.memo(function TabDetails(props: React.PropsWithChildren<{
  icon?: IconDefinition | JSX.Element;
  label?: string | null;
}>): JSX.Element {
  const currentTab = React.useContext(CurrentTabContext);
  const previousRef = React.useRef<{ icon: IconDefinition | JSX.Element, label: string } | null>(null);
  const setTabs = React.useContext(SetTabDetailsCtx);

  if (setTabs == null) {
    throw new Error("SetTabsCtx is null");
  }

  React.useEffect(() => {
    document.title = props.label ?? (document.title);
    setTabs(t => {
      const i = t.findIndex(t1 => t1.uuid === currentTab);

      if (i !== -1) {
        if (previousRef.current == null) {
          previousRef.current = {
            icon: t[i].icon,
            label: t[i].label
          };
        }

        if (props.icon != null) t[i].icon = props.icon;
        if (props.label != null) t[i].label = props.label;
      }

      return [...t];
    });

    return () => {
      if (previousRef.current != null) {
        const prev = previousRef.current;

        document.title = prev.label ?? (document.title);

        setTabs(t => {
          const i = t.findIndex(t1 => t1.uuid === currentTab);

          if (i !== -1) {
            if (prev.icon != null) t[i].icon = prev.icon;
            if (prev.label != null) t[i].label = prev.label;
          }

          return [...t];
        });
      }
    };
  }, [currentTab, props.icon, props.label, setTabs]);


  return props.children as JSX.Element;
});

export const TabsProvider = React.memo(function TabsProvider(props: React.PropsWithChildren): JSX.Element {
  console.debug('Rendering <TabsProvider>');

  const [tabs, setTabs] = React.useState<Tab[]>(initialTabs);
  const [tabDetails, setTabDetails] = React.useState<TabDetails[]>(initialTabs.map(i => ({
    uuid: i.uuid,
    label: "New Tab",
    icon: faSparkles
  })));
  const [activeTab, setActiveTab] = React.useState(initialTabs[0].uuid);
  const tabsRef = React.useRef(tabs);
  tabsRef.current = tabs;

  const value = React.useMemo(() => ({ tabs, setTabs, activeTab, setActiveTab }), [activeTab, tabs, setActiveTab]);

  React.useEffect(() => {
    window.addEventListener("keydown", evt => {
      if (evt.altKey && evt.metaKey && evt.key === "ArrowRight") {
        setActiveTab(current => {
          const currentIndex = tabsRef.current.findIndex(t => t.uuid === current);
          const newIndex = currentIndex + 1;
          if (newIndex === tabsRef.current.length) {
            return tabsRef.current[0].uuid;
          }
          else {
            return tabsRef.current[newIndex].uuid;
          }
        });
      }
      if (evt.altKey && evt.metaKey && evt.key === "ArrowLeft") {
        setActiveTab(current => {
          const currentIndex = tabsRef.current.findIndex(t => t.uuid === current);
          const newIndex = currentIndex - 1;
          if (newIndex === -1) {
            return tabsRef.current[tabsRef.current.length - 1].uuid;
          }
          else {
            return tabsRef.current[newIndex].uuid;
          }
        });
      }
      if ((evt.ctrlKey || evt.metaKey) && evt.key === "t") {
        const uuid = crypto.randomUUID();
        setTabDetails(t => ([...t, {
          uuid,
          label: "New Tab",
          icon: faSparkles
        }]));
        setTabs(t => ([...t, {
          uuid,
        }]));
        setActiveTab(uuid);
      }
    });
  }, []);

  const onNewTab = React.useCallback(() => {
    const uuid = crypto.randomUUID();

    setTabDetails(t => ([...t, {
      uuid,
      label: "New Tab",
      icon: faSparkles
    }]));
    setTabs(t => ([...t, {
      uuid,
    }]));
    setActiveTab(uuid);
  }, []);

  return (
    <TabsContext.Provider value={value}>
      <SetTabsCtx.Provider value={setTabs}>
        <SetTabDetailsCtx.Provider value={setTabDetails}>
          <TabDetailsCtx.Provider value={tabDetails}>
            <OnNewTabCtx.Provider value={onNewTab}>
              {props.children}
            </OnNewTabCtx.Provider>
          </TabDetailsCtx.Provider>
        </SetTabDetailsCtx.Provider>
      </SetTabsCtx.Provider>
    </TabsContext.Provider>
  );
});