/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TaskStatusV3 } from "../../types/tasks/tasks-organization";

export type ParamsType = {
  status: TaskStatusV3;
  taskUUID: string;
  order: null | {
    [k: string]: string[]
  }
};
export type ResponseType = void;
export const Endpoint = "tasks/update-task-status";
export const Method = "POST";

export type Plug = {
  params: ParamsType,
  response: ResponseType,
  method: typeof Method,
  endpoint: typeof Endpoint,
};

export const RateLimitingConfig = {
  calls: 3,
  per: 500,
};export const paramsSchema = {"type":"object","properties":{"status":{"$ref":"#/definitions/TaskStatusV3"},"taskUUID":{"type":"string"},"order":{"anyOf":[{"type":"object","additionalProperties":{"type":"array","items":{"type":"string"}}},{"type":"null"}]}},"required":["order","status","taskUUID"],"definitions":{"TaskStatusV3":{"type":"object","properties":{"uuid":{"type":"string"},"label":{"type":"string"},"color":{"$ref":"#/definitions/TaskColorsEnum"},"projectUUID":{"description":"Project this status is for (null for global, undefined for Hiyllo defaults)","type":["null","string"]},"default":{"type":"boolean"},"closed":{"description":"If the task should be completed \"closed\" when this status is set","type":"boolean"}},"required":["color","label","uuid"]},"TaskColorsEnum":{"enum":["amber","aqua","blue","gray","green","red"],"type":"string"}},"$schema":"http://json-schema.org/draft-07/schema#"}