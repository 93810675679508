import React from 'react';

export const GradientLoop = ({ children, gradient = ['#ff6b6b', '#4ecdc4'], outerStyle, innerStyle, speed, angle }: React.PropsWithChildren<{
  gradient?: [string, string],
  outerStyle?: React.CSSProperties
  innerStyle?: React.CSSProperties
  speed?: number,
  angle?: string
}>) => {
  // Validate gradient input
  if (!Array.isArray(gradient) || gradient.length !== 2) {
    console.warn('Gradient must be an array of two colors');
    gradient = ['#ff6b6b', '#4ecdc4'];
  }

  const containerStyle: React.CSSProperties = React.useMemo(() => {
    return {
      position: 'relative',
      width: '100%',
      height: '128px',
      overflow: 'hidden',
      ...(outerStyle ?? {})
    };
  }, [outerStyle]);

  // Create a repeating gradient pattern that's exactly twice as long
  // This ensures the end matches perfectly with the start
  const gradientStyle: React.CSSProperties = {
    position: 'absolute',
    width: '500%', // Increased width for smoother transition
    height: '100%',
    background: `linear-gradient(${angle ?? "to right"}, 
      ${gradient[0]} 0%,
      ${gradient[1]} 25%,
      ${gradient[0]} 50%,
      ${gradient[1]} 75%,
      ${gradient[0]} 100%
    )`,
    animation: `slide ${speed ?? 10}s linear infinite` // Adjusted timing for smoother motion
  };

  // Keyframes now move the gradient exactly one pattern length
  const keyframesStyle = `
    @keyframes slide {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-50%); // Move by half the total width (one full pattern)
      }
    }
  `;

  return (
    <>
      <style>{keyframesStyle}</style>
      <div style={containerStyle}>
        <div style={gradientStyle} />
        <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", ...(innerStyle ?? {}) }}>
          {children}
        </div>
      </div>
    </>
  );
};