import React from "react";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { seamlessClient } from "../../../../seamless-client";
import * as GetAlertsBP from "../../../../blueprints/alerts/get-unread-alerts";
import * as MarkAlertsReadBP from "../../../../blueprints/alerts/mark-alerts-read";
import {
  AlertFeature,
  type AlertType,
} from "@hiyllo/omni-common/src/types/alerts/alert";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBellSlash,
  faCalendar,
  faComments,
  faEmptySet,
  faEnvelope,
  faPhoneAlt,
  faSquareKanban,
  faUmbrellaBeach,
} from "@fortawesome/pro-light-svg-icons";
import { styled } from "@hiyllo/ux/styled";
import { CallBanner } from "./call-banner";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { useTheme } from "@hiyllo/ux/theme";
import { MuteController } from "../dock/mute-controller";
import { AlertRenderer } from "./alert-renderer";

const ALERTS_WINDOW_WIDTH = 360;
const AlertWindowClearAllLink = styled("div", {
  textAlign: "right",
  padding: 10,
  cursor: "pointer",
});

const AlertWindowHeader = styled("div", {
  padding: 10,
  userSelect: "none",
  fontWeight: "bold",
});

const AlertWindowHeaderRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  // borderBottom: "1px solid #3a3a3a",
});

const AlertWindowWithContent = styled("div", {
  height: "100%",
  width: "100%",
  gap: 2,
  display: "flex",
  flexDirection: "column",
  overflowX: "hidden",
});

const AlertWindowNoContent = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  color: "white",
  userSelect: "none",
  overflowX: "hidden",
});

const AlertWindowContainer = styled("div", ({ $theme }) => ({
  width: ALERTS_WINDOW_WIDTH,
  height: "100%",
  overflowX: "hidden",
  color: $theme.foreground,
  fontFamily: "hiyllo",
  position: "relative",
  backgroundColor: $theme.background3,
  borderRadius: 10,
  borderBottomLeftRadius: 0,
}));

const InnerNoAlerts = styled("div", {
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const AlertTabRow = styled("div", {
  height: 40,
  display: "flex",
  flexDirection: "row",
  justifyContent: "stretch",
});

const AlertWindowTabItem = styled("div", {
  height: 40,
  flexGrow: 1,
  position: "relative",
});

const AlertWindowTabItemInner = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  gap: 5,
  height: 39,
  width: "100%",
  borderBottom: "1px #666 solid",
});

const AlertUnderline = styled<"div">(motion.div, ({ $theme }) => ({
  height: 1,
  background: $theme.buttonBackground,
  width: "100%",
  bottom: 0,
  position: "absolute",
  zIndex: 1000,
})) as typeof motion.div;

export const AlertsWindow = React.memo(function AlertsWindow(props: {
  onClose: () => void;
  alertsCountQuery: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const $theme = useTheme();
  const alertsQuery = seamlessClient.useQuery<GetAlertsBP.Plug>(
    GetAlertsBP,
    null,
  );
  const markAlertsRead =
    seamlessClient.useMutation<MarkAlertsReadBP.Plug>(
      MarkAlertsReadBP,
      { querySideEffects: [alertsQuery, props.alertsCountQuery] },
    );
  const notReady = alertsQuery.isLoading || alertsQuery.isError;
  const unfilteredAlerts: AlertType[] = React.useMemo(
    () => (notReady ? [] : alertsQuery.data.alerts),
    [alertsQuery.data?.alerts, notReady],
  );
  const ALERTS_WINDOW_HEIGHT = 320;

  const [selected, setSelected] = React.useState<AlertFeature>(
    AlertFeature.chat,
  );

  const alerts = unfilteredAlerts.filter((a) => a.feature === selected);

  const clearAllAlerts = React.useCallback(() => {
    void markAlertsRead.call({
      alertUUIDs: alerts.map((a) => a.uuid),
    });
  }, [alerts, markAlertsRead]);

  const hasOtherAlerts =
    unfilteredAlerts.filter((a) => a.feature === AlertFeature.other).length > 0;

  const callAlert = unfilteredAlerts.find(
    (a) => a.actions?.find((a) => a.format === "join-meeting") != null,
  );

  const uncollapsedAlerts = alerts.filter((a) => a.collapseOnLabel == null);
  const collapsedAlerts: Record<string, AlertType[]> = {};
  alerts.forEach((alert) => {
    if (alert.collapseOnLabel != null) {
      if (collapsedAlerts[alert.collapseOnLabel] == null) {
        collapsedAlerts[alert.collapseOnLabel] = [];
      }
      collapsedAlerts[alert.collapseOnLabel].push(alert);
    }
  });

  return (
    <motion.div
      style={{
        position: "absolute",
        left: "calc(100% + 0px)",
        bottom: 0,
        height: ALERTS_WINDOW_HEIGHT,
        width: ALERTS_WINDOW_WIDTH,
        overflow: "visible",
        zIndex: 6,
      }}
      initial={{ width: 0 }}
      animate={{ width: ALERTS_WINDOW_WIDTH }}
      exit={{
        width: 0,
        transition: { duration: 0.15, delay: 0.05 },
      }}
      transition={{
        bounce: 0,
        type: "spring",
        duration: 0.3,
      }}
      onMouseDown={(evt) => evt.stopPropagation()}
    >
      <div style={{ position: "relative", height: "100%" }}>
        <motion.div
          style={{ position: "absolute", top: ALERTS_WINDOW_HEIGHT + 10, left: 10, zIndex: -1 }}
          initial={{ opacity: 0, y: -50 }}
          exit={{ y: -50, opacity: 0, transition: { delay: 0, duration: 0.15 } }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.15 }}
        >
          <div>
            <MuteController />
          </div>
        </motion.div>
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: ALERTS_WINDOW_WIDTH }}
          style={{
            height: "100%",
            overflow: "hidden",
          }}
          exit={{
            width: 0,
            transition: { duration: 0.15, delay: 0.05 },
          }}
          transition={{
            bounce: 0,
            type: "spring",
            duration: 0.3,
          }}
        >
          <AlertWindowContainer>
            {unfilteredAlerts.length > 0 ? (
              <AlertWindowWithContent>
                <AlertTabRow>
                  <AlertWindowTabItem
                    onClick={() => setSelected(AlertFeature.chat)}
                  >
                    <AlertWindowTabItemInner>
                      <FontAwesomeIcon icon={faComments} />
                      {
                        unfilteredAlerts.filter(
                          (a) => a.feature === AlertFeature.chat,
                        ).length
                      }
                    </AlertWindowTabItemInner>
                    {selected === AlertFeature.chat ? (
                      <AlertUnderline layoutId="alertunderline" />
                    ) : null}
                  </AlertWindowTabItem>
                  <AlertWindowTabItem
                    onClick={() => setSelected(AlertFeature.calendar)}
                  >
                    <AlertWindowTabItemInner>
                      <FontAwesomeIcon icon={faCalendar} />
                      {
                        unfilteredAlerts.filter(
                          (a) => a.feature === AlertFeature.calendar,
                        ).length
                      }
                    </AlertWindowTabItemInner>
                    {selected === AlertFeature.calendar ? (
                      <AlertUnderline layoutId="alertunderline" />
                    ) : null}
                  </AlertWindowTabItem>
                  <AlertWindowTabItem
                    onClick={() => setSelected(AlertFeature.call)}
                  >
                    <AlertWindowTabItemInner>
                      <FontAwesomeIcon icon={faPhoneAlt} />
                      {
                        unfilteredAlerts.filter(
                          (a) => a.feature === AlertFeature.call,
                        ).length
                      }
                    </AlertWindowTabItemInner>
                    {selected === AlertFeature.call ? (
                      <AlertUnderline layoutId="alertunderline" />
                    ) : null}
                  </AlertWindowTabItem>
                  <AlertWindowTabItem
                    onClick={() => setSelected(AlertFeature.tasks)}
                  >
                    <AlertWindowTabItemInner>
                      <FontAwesomeIcon icon={faSquareKanban} />
                      {
                        unfilteredAlerts.filter(
                          (a) => a.feature === AlertFeature.tasks,
                        ).length
                      }
                    </AlertWindowTabItemInner>
                    {selected === AlertFeature.tasks ? (
                      <AlertUnderline layoutId="alertunderline" />
                    ) : null}
                  </AlertWindowTabItem>
                  <AlertWindowTabItem
                    onClick={() => setSelected(AlertFeature.mail)}
                  >
                    <AlertWindowTabItemInner>
                      <FontAwesomeIcon icon={faEnvelope} />
                      {
                        unfilteredAlerts.filter(
                          (a) => a.feature === AlertFeature.mail,
                        ).length
                      }
                    </AlertWindowTabItemInner>
                    {selected === AlertFeature.mail ? (
                      <AlertUnderline layoutId="alertunderline" />
                    ) : null}
                  </AlertWindowTabItem>
                  {hasOtherAlerts ? (
                    <AlertWindowTabItem
                      onClick={() => setSelected(AlertFeature.other)}
                    >
                      <AlertWindowTabItemInner>
                        <FontAwesomeIcon icon={faBell} />
                        {
                          unfilteredAlerts.filter(
                            (a) => a.feature === AlertFeature.other,
                          ).length
                        }
                      </AlertWindowTabItemInner>
                      {selected === AlertFeature.other ? (
                        <AlertUnderline layoutId="alertunderline" />
                      ) : null}
                    </AlertWindowTabItem>
                  ) : null}
                </AlertTabRow>
                <div style={{ height: 0, flexGrow: 1, overflowY: "auto" }}>
                  {alerts.length !== 0 ? (
                    <AlertWindowHeaderRow>
                      <AlertWindowHeader>
                        {alerts.length} Alert
                        {alerts.length === 1 ? "" : "s"}
                      </AlertWindowHeader>
                      <AlertWindowClearAllLink onClick={clearAllAlerts}>
                        Clear All
                      </AlertWindowClearAllLink>
                    </AlertWindowHeaderRow>
                  ) : null}
                  {alerts.length === 0 ? (
                    <InnerNoAlerts>
                      <FontAwesomeIcon
                        icon={faBellSlash}
                        style={{ fontSize: 40, marginBottom: 10 }}
                      />
                      No Alerts Here
                      <div style={{ fontSize: 12, marginTop: 2.5 }}>
                        Try a different tab?
                      </div>
                    </InnerNoAlerts>
                  ) : (
                    <div style={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
                      {uncollapsedAlerts.map((alert) => (
                        <AlertRenderer
                          key={alert.uuid}
                          alert={alert}
                          alertsCountQuery={props.alertsCountQuery}
                          alertsQuery={alertsQuery}
                          onClose={props.onClose}
                        />
                      ))}
                      {Object.entries(collapsedAlerts).map(([label, alerts]) => (
                        <React.Fragment key={label}>
                          <div
                            style={{
                              padding: 7.5,
                              paddingTop: 0,
                              paddingBottom: 5,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: 20,
                            }}
                          >
                            <div
                              style={{
                                height: 0.5,
                                width: 0,
                                flexGrow: 1,
                                background: "white",
                              }}
                            />
                            {label} ({alerts.length})
                            <div
                              style={{
                                height: 0.5,
                                width: 0,
                                flexGrow: 1,
                                background: "white",
                              }}
                            />
                          </div>
                          {alerts.map((alert) => (
                            <AlertRenderer
                              key={alert.uuid}
                              alert={alert}
                              alertsCountQuery={props.alertsCountQuery}
                              alertsQuery={alertsQuery}
                              onClose={props.onClose}
                            />
                          ))}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </div>
                {callAlert != null ? (
                  <CallBanner alert={callAlert} onClose={props.onClose} />
                ) : null}
              </AlertWindowWithContent>
            ) : (
              <AlertWindowNoContent>
                {alertsQuery.isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <EmptySplash icon={faUmbrellaBeach} label="No Alerts" hint="Time for a break?" />
                )}
              </AlertWindowNoContent>
            )}
          </AlertWindowContainer>
        </motion.div>
      </div>
    </motion.div>
  );
});
