/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { ContinuityAccessType } from "../continuity/access";

export type TaskOrderType = {
  [k: string]: string[];
};

/**
 * AI generated summary of the project
 */
export interface ProjectSummaryType {
  summary: string;
  lastUpdated: Date;
}

export interface TaskProject {
  name: string;
  uuid: string;
  access: ContinuityAccessType;
  order: TaskOrderType;
  summary?: ProjectSummaryType | null;
  isPersonalProjectFor?: string | null;
  archived?: boolean;
  statusOptions?: TaskStatusV3[] | null;
  priorityOptions?: TaskPriorityV3[] | null;
  _v?: 2;
}

export enum TaskColorsEnum {
  red = "red", // P0
  amber = "amber", // P1
  green = "green", // P4
  blue = "blue", // P2
  gray = "gray",
  aqua = "aqua", // P3
}

export enum TaskIconsEnum {
  doubleAngleUp = "double-angle-up",
  singleAngleUp = "single-angle-up",
  doubleLineCenter = "double-line-center",
  singleAngleDown = "single-angle-down",
  doubleAngleDown = "double-angle-down",
}

export const HIYLLO_DEFAULT_STATUSES: TaskStatusV3[] = [
  {
    uuid: "todo",
    label: "To-Do",
    color: TaskColorsEnum.gray,
    default: true,
  },
  {
    uuid: "inprogress",
    label: "In Progress",
    color: TaskColorsEnum.blue,
  },
  {
    uuid: "inreview",
    label: "In Review",
    color: TaskColorsEnum.blue,
  },
  {
    uuid: "done",
    label: "Done",
    color: TaskColorsEnum.green,
    closed: true,
  },
  {
    uuid: "wontdo",
    label: "Won't Do",
    color: TaskColorsEnum.red,
    closed: true,
    hideFromKanban: true
  },
];

export const HIYLLO_DEFAULT_PRIORITIES: TaskPriorityV3[] = [
  {
    order: 0,
    label: "P0",
    color: TaskColorsEnum.red,
    icon: TaskIconsEnum.doubleAngleUp,
    uuid: "0",
  },
  {
    order: 1,
    label: "P1",
    color: TaskColorsEnum.amber,
    icon: TaskIconsEnum.singleAngleUp,
    uuid: "1",
  },
  {
    order: 2,
    label: "P2",
    color: TaskColorsEnum.blue,
    icon: TaskIconsEnum.doubleLineCenter,
    uuid: "2",
  },
  {
    order: 3,
    label: "P3",
    color: TaskColorsEnum.aqua,
    icon: TaskIconsEnum.singleAngleDown,
    uuid: "3",
  },
  {
    order: 4,
    label: "P4",
    color: TaskColorsEnum.green,
    icon: TaskIconsEnum.doubleAngleDown,
    uuid: "4",
  },
]

export interface TaskStatusV3 {
  uuid: string;
  label: string;
  color: TaskColorsEnum;
  /**
   * Project this status is for (null for global, undefined for Hiyllo defaults)
   */
  projectUUID?: string | null;
  default?: boolean;
  /**
   * If the task should be completed "closed" when this status is set
   */
  closed?: boolean;

  hideFromKanban?: boolean;
}

export interface TaskPriorityV3 {
  uuid: string;
  order: number;
  label: string;
  color: TaskColorsEnum;
  icon: TaskIconsEnum;
  /**
   * Project this priority is for (null for global, undefined for Hiyllo defaults)
   */
  projectUUID?: string | null;
  default?: boolean;
  hideFromKanban?: boolean;
}