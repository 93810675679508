import React from "react";
import { SearchOverlay } from "@hiyllo/omni-search";
import {
  type SearchResultType,
  type TaskResultType,
} from "../../../types/search/search-results";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/pro-light-svg-icons";
import { Modal } from "@hiyllo/ux/modal";
import { CreateNewTaskView } from "../views/create-new-task";
import { Button } from "@hiyllo/ux/button";

const InputPlaceholder = styled<"div">("div", ({ $theme }) => ({
  borderColor: $theme.background3,
  borderWidth: 2,
  borderStyle: "solid",
  cursor: "pointer",
  borderRadius: 8,
  padding: 15,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 15,
  flexDirection: "row",
  flexBasis: "50%",
  flexGrow: 0
}));

export const AddExistingTaskView = React.memo(
  function AddExistingTaskView(props: {
    onTaskSelected: (taskUUID: string) => void;
    sprintUUID?: string;
  }): JSX.Element {
    const [searchForTask, setSearchForTask] = React.useState(false);
    const [createTask, setCreateTask] = React.useState(false);

    return (
      <>
        {searchForTask ? (
          <SearchOverlay
            onClose={() => setSearchForTask(false)}
            filterType={["task"]}
            onSelectResult={(r: SearchResultType) => {
              // Since we filterType to task we can assume it is a TaskResultType
              const result = r as TaskResultType;
              props.onTaskSelected(result.task.uuid);
              setSearchForTask(false);
            }}
          />
        ) : null}
        {createTask ? (
          <Modal onClose={() => setCreateTask(false)}>
            <div style={{ width: "min(75%, 750px)" }}>
              <CreateNewTaskView
                onTaskCreated={taskUUID => {
                  props.onTaskSelected(taskUUID);
                  setCreateTask(false);
                }}
                projectUUID={null}
                sprintUUID={props.sprintUUID ?? null}
                statusUUID={null}
                assigneeId={null}
              />
            </div>
          </Modal>
        ) : null}
        <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "stretch" }}>
          <div style={{ flexGrow: 1 }}>
            <Button
              isSecondary
              onClick={() => setSearchForTask(true)}
              label={
                <>
                  <FontAwesomeIcon icon={faSearch} />
                  Add Existing Task
                </>
              }
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <Button
              isSecondary
              onClick={() => setCreateTask(true)}
              label={
                <>
                  <FontAwesomeIcon icon={faPlus} /> Create New Task
                </>
              }
            />
          </div>
        </div>
      </>
    );
  },
);
