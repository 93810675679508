import React from "react";
import { TasksView } from "./tasks-view";
import * as ListTasksForUserBP from "../../../blueprints/tasks/list-tasks-for-user";
import { seamlessClient } from "../../../seamless-client";

export const UserTasksView = React.memo(function TaskGroupView({
  userId,
}: {
  userId: string;
}): JSX.Element {
  const listTasksForUserQuery =
    seamlessClient.useQuery<ListTasksForUserBP.Plug>(
      ListTasksForUserBP,
      { userId, filter: null },
    );

  return (
    <TasksView
      order={{}}
      onChangeOrder={() => {
        //
      }}
      tasks={listTasksForUserQuery.data?.tasks ?? []}
      isReady={!listTasksForUserQuery.isLoading}
      cacheKey={userId}
    />
  );
});
