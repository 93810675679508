import React from "react";

import {
  TaskType,
  LABEL_BY_TASK_TYPE,
} from "../../../types/tasks/task-item";
import { Select } from "@hiyllo/ux/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { IconsByTaskType } from "../constants/icons-by-task-type";

interface PropsType {
  type: TaskType;
  onChangeType: null | ((newValue: TaskType) => void);
}

const OPTIONS = [
  {
    icon: IconsByTaskType[TaskType.epic],
    value: TaskType.epic,
    label: LABEL_BY_TASK_TYPE[TaskType.epic],
  },
  {
    icon: IconsByTaskType[TaskType.story],
    value: TaskType.story,
    label: LABEL_BY_TASK_TYPE[TaskType.story],
  },
  {
    icon: IconsByTaskType[TaskType.task],
    value: TaskType.task,
    label: LABEL_BY_TASK_TYPE[TaskType.task],
  },
];

export const TypePill = React.memo(function TypePill({
  type,
  onChangeType,
}: PropsType): JSX.Element {
  const selectedOption = OPTIONS.find((o) => o.value === type);

  if (!selectedOption) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <Select
      options={OPTIONS}
      disabled={onChangeType === null}
      value={type}
      onChangeValue={(id) => {
        if (onChangeType != null) {
          onChangeType(id as TaskType);
        }
      }}
      containerStyle={{ width: "min-content" }}
      customStaticElement={
        <div
          style={{
            background: "#4d4c50",
            fontSize: 12,
            paddingLeft: 5,
            paddingRight: 5,
            height: 25,
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 5,
            cursor: "pointer",
            whiteSpace: "nowrap",
            width: "min-content",
          }}
        >
          <div
            style={{
              paddingLeft: 2.5,
              paddingRight: onChangeType != null ? 0 : 2.5,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2.5,
            }}
          >
            <FontAwesomeIcon icon={selectedOption.icon} fixedWidth />
            {selectedOption.label}
          </div>
          {onChangeType != null ? <FontAwesomeIcon icon={faCaretDown} /> : null}
        </div>
      }
    />
  );
});
