import React from "react";
import { Card } from "@hiyllo/ux/surface";
import { styled } from "@hiyllo/ux/styled";
import { useNavigate } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { useCreateTaskProject } from "../hooks/use-create-task-project";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/pro-light-svg-icons";
import { Input, useInputControl } from "@hiyllo/ux/input";
import { useListMyTeams } from "../../organization/hooks/use-list-my-teams";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { useIsSolo } from "../../../platform/hooks/use-is-solo";
import { ContinuityAccessControl } from "../../continuity/people/components/continuity-access-control";
import { type ContinuityAccessType } from "../../../types/continuity/access";
import { Typography } from "@hiyllo/ux/typography";
import { WarningCard } from "../../../ux/warning-card";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";
import { Button } from "@hiyllo/ux/button";

const Header = styled("div", {
  fontSize: 24,
  lineHeight: "1em",
});

const Description = styled("div", {
  fontSize: 16,
  lineHeight: "1.25em",
});

const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 30,
  justifyContent: "stretch",
  width: "100%",
});

const GradientBanner = styled("div", ({ $theme }) => ({
  padding: 40,
  borderRadius: 10,
  background: $theme.buttonBackground,
  textAlign: "center",
  fontSize: 36,
  marginBottom: 15,
}));

const Spacer = styled("div", { height: 24 });

export const CreateNewProjectView = React.memo(
  function CreateNewProjectView(props: {
    projectsQuery: UseMoopsyQueryRetValAny;
  }): JSX.Element {
    const isSolo = useIsSolo();
    const teamsQuery = useListMyTeams(null);
    const createTaskProjectMutation = useCreateTaskProject({
      querySideEffects: [props.projectsQuery],
    });
    const nameInputRef = useInputControl();
    const navigate = useNavigate();
    const [error, setError] = React.useState<string | null>(null);
    const [access, setAccess] = React.useState<ContinuityAccessType>({
      users: [],
      teams: [],
    });
    const myTeamsQuery = useListMyTeams(null);
    const hasAccess = access.teams.some(
      (t) => myTeamsQuery.data?.teams.some((mt) => mt.uuid === t),
    );

    const createProject = React.useCallback(() => {
      setError(null);

      if (nameInputRef.valueRef.current == null) {
        return;
      }

      if (access.teams.length === 0 && !isSolo) {
        return setError("Select at least one team");
      }

      const name = nameInputRef.valueRef.current;

      if (name.length < 1) {
        return setError("Enter a project name");
      }

      void createTaskProjectMutation
        .call({
          name,
          access: isSolo
            ? {
              teams: [teamsQuery.data?.teams?.[0]?.uuid ?? "noop"],
              users: [],
            }
            : access,
        })
        .then((res) => {
          navigate({
            feature: Features.tasks,
            params: {
              view: "project",
              projectUUID: res.uuid,
            },
          });
        });
    }, [
      access,
      createTaskProjectMutation,
      isSolo,
      navigate,
      teamsQuery.data?.teams,
    ]);

    return (
      <Row>
        <Card color="background3" style={{ flexGrow: 1 }}>
          <Header>Create Project</Header>
          <Spacer />
          <div>
            <Typography.Label>Project Name</Typography.Label>
            <Input type="text" {...nameInputRef.inputProps} />
          </div>

          {!isSolo ? (
            <>
              <Spacer />
              <div>
                <ContinuityAccessControl
                  value={access}
                  onChange={setAccess}
                  filterTo={["organization/team"]}
                  header="Teams with access"
                />
              </div>
            </>
          ) : null}
          <Spacer />
          <AnimateChangeInHeight>
            {hasAccess || access.teams.length === 0 ? null : (
              <>
                <WarningCard>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  You can create this project, but you won&apos;t be able to
                  access it since you aren&apos;t a member of any of the teams
                  you&apos;ve selected.
                </WarningCard>
                <Spacer />
              </>
            )}
          </AnimateChangeInHeight>
          <Button
            label="Create Project"
            isLoading={createTaskProjectMutation.isLoading}
            onClick={createProject}
            autoWidth
          />
          {error ? (
            <>
              <Spacer />
              <div style={{ color: "red" }}>{error}</div>
            </>
          ) : null}
        </Card>
        <Card
          color="background2"
          style={{
            flexGrow: 1,
            maxWidth: 400,
          }}
        >
          <Description>
            <FontAwesomeIcon icon={faInfoCircle} />
            &nbsp;&nbsp;&nbsp;What are Projects?
          </Description>
          <Spacer />
          <GradientBanner>
            <div>Projects</div>
          </GradientBanner>
          {isSolo ? (
            <>
              <Description>
                <b>Projects are how you organize tasks in Solo.</b>
              </Description>
              <Spacer />
              <Description>
                Projects can represent a goal you&apos;re trying to acomplish,
                help you separate your work from your personal tasks, whatever
                you want!
              </Description>
            </>
          ) : (
            <>
              <Description>
                <b>Projects are how you organize tasks in your WorkSpace.</b>
              </Description>
              <Spacer />
              <Description>
                A project should represent a single goal or objective for your
                team or company.
              </Description>
              <Spacer />
              <Description>
                You can share your project with teams and individual coworkers.
              </Description>
            </>
          )}
        </Card>
      </Row>
    );
  },
);
