import React from "react";
import moment from "moment";
import { useLookupByUserId, useSelf } from "@hiyllo/omni-continuity";
import { Typography } from "@hiyllo/ux/typography";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { faEmptySet, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";

import * as GetAllTimecardsBP from "../../../blueprints/talent/get-all-timecards";
import * as ClockInBP from "../../../blueprints/talent/clock-in";
import * as GetUserBP from "../../../blueprints/admin/user-management/get-user-admin";
import * as ClockOutBP from "../../../blueprints/talent/clock-out";

import { seamlessClient } from "../../../seamless-client";
import { TimecardStatusEnum, TimecardType } from "../../../types/talent/timecard";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { Button } from "@hiyllo/ux/button";
import { Structure } from "@hiyllo/ux/structure";
import { Table, TableCell, TableRow } from "@hiyllo/ux/table";

const TimecardRow = React.memo(function TimecardRow({ timecard }: { timecard: TimecardType }): JSX.Element {
  const lookup = seamlessClient.useQuery<GetUserBP.Plug>(GetUserBP, { userUUID: timecard.userUUID });

  return (
    <TableRow>
      <TableCell>
        {lookup.data?.user.profile?.name}
      </TableCell>
      <TableCell>
        {moment(timecard.clockIn).format("h:mm a, MMM Do")}
      </TableCell>
      <TableCell>
        {timecard.clockOut == null ? "N/A (Still clocked in)" : moment(timecard.clockOut).format("h:mm a, MMM Do")}
      </TableCell>
      <TableCell>
        {timecard.recordedHours}
      </TableCell>
    </TableRow>
  );
});

export const AdminDashboard = React.memo(function AdminDashboard(): JSX.Element {
  const getAllTimecardsQuery = seamlessClient.useQuery<GetAllTimecardsBP.Plug>(GetAllTimecardsBP, null);

  if (getAllTimecardsQuery.isLoading) {
    return <LoadingSpinnerFullView />;
  }

  if (getAllTimecardsQuery.isError) {
    return (
      <EmptySplash
        icon={faExclamationTriangle}
        label="Error Loading Timecards"
        hint={getAllTimecardsQuery.error.message}
      />
    );
  }

  return (
    <div style={{ padding: 50 }}>
      <Typography.SubHeader>Timecards this week</Typography.SubHeader>
      <div style={{ height: 5 }} />
      <Table
        header={["Employee", "Clock-In", "Clock-Out", "Hours"]}
        $gridTemplateColumns="auto auto auto auto"
      >
        {getAllTimecardsQuery.data?.timecards.map(timecard => (
          <TimecardRow key={timecard.uuid} timecard={timecard} />
        ))}
      </Table>

      <div style={{ height: 20 }} />
      <Typography.SubHeader>Details</Typography.SubHeader>
      <div style={{ height: 5 }} />
      <div>
        Timecards are rounded to the nearest 15 minute interval
      </div>
    </div>
  );
});