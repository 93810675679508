import React from "react";
import { seamlessClient } from "../seamless-client";
import { TransportStatus } from "@moopsyjs/react";
import { ReconnectingBanner } from "../platform/components/reconnecting-banner";
import { useCachedSelf } from "@hiyllo/omni-continuity";
import { LoadingPage } from '@hiyllo/ux/standard-loader';

export const ConnectionProvider = React.memo(
  function ConnectionProvider(props: { children: JSX.Element }): JSX.Element {
    const cachedSelf = useCachedSelf();
    const status = seamlessClient.use.transportStatus();

    const [initialConnectionEstablished, setInitialConnectionEstablished] =
      React.useState(false);

    React.useEffect(() => {
      seamlessClient.onConnected(() => {
        setInitialConnectionEstablished(true);
      });
    });

    if (
      status !== TransportStatus.connected &&
      !initialConnectionEstablished &&
      cachedSelf == null
    ) {
      return <LoadingPage />;
    }

    return (
      <>
        {props.children}
        {status !== TransportStatus.connected ? <ReconnectingBanner /> : null}
      </>
    );
  },
);
