import React from "react";
import moment from "moment";
import { useSelf } from "@hiyllo/omni-continuity";
import { Typography } from "@hiyllo/ux/typography";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { faEmptySet, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";

import * as GetMyTimecardsBP from "../../../blueprints/talent/get-my-timecards";
import * as ClockInBP from "../../../blueprints/talent/clock-in";
import * as ClockOutBP from "../../../blueprints/talent/clock-out";

import { seamlessClient } from "../../../seamless-client";
import { TimecardStatusEnum, TimecardType } from "../../../types/talent/timecard";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { Button } from "@hiyllo/ux/button";
import { Structure } from "@hiyllo/ux/structure";
import { Table, TableCell, TableRow } from "@hiyllo/ux/table";
import { useShowAlert } from "@hiyllo/ux/dialogs";
import { MoopsyError } from "@moopsyjs/core/main";

export const ICDashboard = React.memo(function ICDashboard(): JSX.Element {
  const showAlert = useShowAlert();
  const self = useSelf();
  const getAllTimecardsQuery = seamlessClient.useQuery<GetMyTimecardsBP.Plug>(GetMyTimecardsBP, null);
  const clockInMutation = seamlessClient.useMutation<ClockInBP.Plug>(ClockInBP, { querySideEffects: [getAllTimecardsQuery] });
  const clockOutMutation = seamlessClient.useMutation<ClockOutBP.Plug>(ClockOutBP, { querySideEffects: [getAllTimecardsQuery] });

  const timecardsThisWeek: TimecardType[] = React.useMemo(() => {
    return getAllTimecardsQuery.data?.timecards.filter(tc => tc.clockIn.valueOf() > moment().day("monday").startOf("day").valueOf()) ?? [];
  }, [getAllTimecardsQuery.data?.timecards]);

  const hoursThisWeek = React.useMemo(() => {
    return timecardsThisWeek.reduce((prev, curr) => {
      return prev + (curr.recordedHours ?? 0);
    }, 0);
  }, [timecardsThisWeek]);

  const payThisWeek = React.useMemo(() => {
    return hoursThisWeek * (self.payroll?.payrate.hourly_rate_e2 ?? 0);
  }, [hoursThisWeek, self.payroll?.payrate.hourly_rate_e2]);

  const activeTimecard: TimecardType | null = React.useMemo(() => {
    return (getAllTimecardsQuery.data?.timecards.find(tc => tc.status === TimecardStatusEnum.clockedIn)) ?? null;
  }, [getAllTimecardsQuery.data?.timecards]);

  const clockIn = React.useCallback(() => {
    void clockInMutation.call(null);
  }, [clockInMutation]);

  const clockOut = React.useCallback(() => {
    clockOutMutation.call(null).catch(err => {
      void showAlert({
        title: "Error Clocking Out",
        message: (err as MoopsyError).message
      });
    });
  }, [clockOutMutation, showAlert]);

  if (self.payroll == null) {
    return (
      <EmptySplash
        icon={faEmptySet}
        label="Not Timecard Eligible"
        hint="Your admin has not setup your account for timecards"
      />
    );
  }

  if (getAllTimecardsQuery.isLoading) {
    return <LoadingSpinnerFullView />;
  }

  if (getAllTimecardsQuery.isError) {
    return (
      <EmptySplash
        icon={faExclamationTriangle}
        label="Error Loading Timecards"
        hint={getAllTimecardsQuery.error.message}
      />
    );
  }

  return (
    <div style={{ padding: 50 }}>
      {activeTimecard == null ?
        <Structure.Row>
          <Typography.Header>You are clocked out</Typography.Header>
          <Button onClick={clockIn} label="Clock In" isLoading={clockInMutation.isLoading} autoWidth />
        </Structure.Row>
        :
        <>
          <Structure.Row>
            <Typography.Header>You are clocked in</Typography.Header>
            <Button onClick={clockOut} label="Clock Out" isLoading={clockOutMutation.isLoading} autoWidth />
          </Structure.Row>
          <div>
            Clocked in since: {moment(activeTimecard.clockIn).format("h:mm a, MMM Do")}
          </div>
        </>
      }

      <div style={{ height: 10 }} />

      <div>
        Payable hours this week: {hoursThisWeek}
      </div>
      <div>
        Maximum payable hours this week: {self.payroll.payrate.max_weekly_hours_current}
      </div>
      <div>
        Your hourly pay rate: ${(self.payroll.payrate.hourly_rate_e2 / 100).toFixed(2)}
      </div>
      {self.payroll.payrate.max_weekly_hours_current !== self.payroll.payrate.max_weekly_hours_next ?
        <div>
          Maximum hours from <b>next work week onwards</b>: {self.payroll.payrate.max_weekly_hours_next}
        </div>
        : null}
      <div>
        Pay this week: ${Math.floor(payThisWeek / 100).toFixed(2)}
      </div>

      <div style={{ height: 20 }} />

      <Typography.SubHeader>Timecards this week</Typography.SubHeader>
      <div style={{ height: 5 }} />
      <Table
        header={["Clock-In", "Clock-Out", "Hours"]}
        $gridTemplateColumns="auto auto auto"
      >
        {timecardsThisWeek.map(timecard => (
          <TableRow key={timecard.uuid}>
            <TableCell>
              {moment(timecard.clockIn).format("h:mm a, MMM Do")}
            </TableCell>
            <TableCell>
              {timecard.clockOut == null ? "N/A (Still clocked in)" : moment(timecard.clockOut).format("h:mm a, MMM Do")}
            </TableCell>
            <TableCell>
              {timecard.recordedHours}
            </TableCell>
          </TableRow>
        ))}
      </Table>

      <div style={{ height: 20 }} />
      <Typography.SubHeader>Details</Typography.SubHeader>
      <div style={{ height: 5 }} />
      <div>
        Timecards are rounded to the nearest 15 minute interval
      </div>
    </div>
  );
});