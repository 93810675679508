import React from "react";
import { LoadingSpinnerFullView } from "../../../../platform/loading/spinner-loading-full";

import * as GetUnfinishedSprintsForTeamBP from "../../../../blueprints/tasks/sprints/get-unfinished-sprints-for-team";
import * as GetSprintBP from "../../../../blueprints/tasks/sprints/get-sprint";
import * as AddTaskToSprintBP from "../../../../blueprints/tasks/sprints/add-task-to-sprint";
import * as RemoveTaskFromSprintBP from "../../../../blueprints/tasks/sprints/remove-task-from-sprint";
import * as StartSprintBP from "../../../../blueprints/tasks/sprints/start-sprint";
import * as EndSprintBP from "../../../../blueprints/tasks/sprints/end-sprint";
import { seamlessClient } from "../../../../seamless-client";
import {
  SprintStatusEnum,
  type Sprint,
} from "@hiyllo/omni-common/src/types/tasks/sprint";
import { CreateSprintView } from "./create-sprint-view";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { Card } from "@hiyllo/ux/surface";
import { styled } from "@hiyllo/ux/styled";
import { Spacer } from "../../../../ux/alpha";
import { useNavigate, useNavigateTo } from "@hiyllo/omni-router";
import { Features } from "@hiyllo/omni-common/src/types/navigation/features";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { useTasksInSprint } from "../hooks/use-tasks-in-sprint";
import { AddExistingTaskView } from "../../components/add-existing-task-view";
import { Select } from "@hiyllo/ux/select";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlarmClock,
  faCalendarDays,
  faChartLineDown,
  faCheck,
  faExclamationTriangle,
  faRunning,
  faSquareKanban,
} from "@fortawesome/pro-light-svg-icons";
import { SPRINT_DURATION_OPTIONS } from "./constants";
import { type ListTasksSlimTaskType } from "@hiyllo/omni-common/src/types/tasks/task-item";
import { SprintPlanningTaskCard, WarningPill } from "./task-card";
import { ICSummary } from "./ic-summary-view";
import { getTaskWarning } from "./helpers/get-task-warning";
import {
  formatEstimateFromWorkDays,
  getTotalSizingInDays,
} from "../../../../utils/tasks/sizing";
import { type TeamType } from "@hiyllo/omni-common/src/types/organization/teams";
import { useGetTeam } from "../../../organization/hooks/use-get-team";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { useMoveUnfinishedTasksToDifferentSprint } from "../../hooks/use-move-unfinished-tasks-to-different-sprint";
import { useGetSprintsForTeam } from "../../hooks/use-get-sprints-for-team";
import { Modal } from "@hiyllo/ux/modal";
import { TaskView } from "../../views/task-view";
import { Button } from "@hiyllo/ux/button";

const sortOrderByStatus: Record<SprintStatusEnum, number> = {
  [SprintStatusEnum.active]: 0,
  [SprintStatusEnum.new]: 1,
  [SprintStatusEnum.completed]: 2,
};

const DetailRow = styled("div", ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  fontSize: 18,
}));

const SprintCardHeader = styled("div", { fontSize: 24 });
const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 10,
});

const CenterRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
});

const SprintCard = React.memo(function SprintCard(props: {
  sprint: Sprint;
}): JSX.Element {
  const viewSprint = useNavigateTo({
    feature: Features.tasks,
    params: {
      view: "sprint-planning",
      sprintUUID: props.sprint.uuid,
      teamUUID: props.sprint.teamUUID,
    },
  });

  const viewSprintKanban = useNavigateTo({
    feature: Features.tasks,
    params: {
      view: "sprint",
      sprintUUID: props.sprint.uuid,
    },
  });

  return (
    <Card color="background2">
      <Row>
        <SprintCardHeader>{props.sprint.name}</SprintCardHeader>
        <CenterRow>
          {props.sprint.status === SprintStatusEnum.active ? (
            <Button
              label={<FontAwesomeIcon icon={faSquareKanban} fixedWidth />}
              onClick={viewSprintKanban}
            />
          ) : null}
          <Button
            label={
              props.sprint.status === SprintStatusEnum.new
                ? "Plan"
                : "View Planning"
            }
            onClick={viewSprint}
          />
        </CenterRow>
      </Row>
    </Card>
  );
});

export const SprintPlanningViewInner = React.memo(
  function SprintPlanningViewInner(props: {
    teamUUID: string;
    team: TeamType;
    sprints: Sprint[];
    teamSprintsQuery: UseMoopsyQueryRetValAny;
  }): JSX.Element {
    const sprints = React.useMemo(() => {
      return props.sprints.sort((a, b) => {
        return sortOrderByStatus[a.status] - sortOrderByStatus[b.status];
      });
    }, [props.sprints]);
    const createNewSprint = useNavigateTo({
      feature: Features.tasks,
      params: {
        view: "create-sprint",
        teamUUID: props.teamUUID,
      },
    });

    if (sprints.length === 0) {
      return (
        <CreateSprintView
          team={props.team}
          teamSprintsQuery={props.teamSprintsQuery}
          rolloverFromSprint={null}
        />
      );
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div style={{ fontSize: 24 }}>{props.team.name}</div>
          <Button
            label="Create Sprint"
            onClick={createNewSprint}
          />
        </div>
        {sprints.map((sprint) => (
          <SprintCard sprint={sprint} key={sprint.uuid} />
        ))}
      </div>
    );
  },
);

export const SprintPlanningListView = React.memo(
  function SprintPlanningListView(props: { teamUUID: string }): JSX.Element {
    const teamSprintsQuery =
      seamlessClient.useQuery<GetUnfinishedSprintsForTeamBP.Plug>(
        GetUnfinishedSprintsForTeamBP,
        { teamUUID: props.teamUUID },
      );
    const teamsQuery = useGetTeam({ teamUUID: props.teamUUID });

    if (
      teamSprintsQuery.isLoading ||
      teamSprintsQuery.isError ||
      teamsQuery.isLoading ||
      teamsQuery.isError
    ) {
      return <LoadingSpinnerFullView />;
    }

    return (
      <SprintPlanningViewInner
        teamUUID={props.teamUUID}
        team={teamsQuery.data.team}
        sprints={teamSprintsQuery.data.sprints}
        teamSprintsQuery={teamSprintsQuery}
      />
    );
  },
);

const SprintPlanningContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
  height: "100%",
  gap: 50,
});

const SprintPlanningSprintName = styled("div", { fontSize: 36 });
const SprintPlanningTeamName = styled("div", { fontSize: 24 });
const SprintPlanningLeftColumn = styled("div", { flexShrink: 0 });
const TasksColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 35,
  minWidth: 300,
  flexGrow: 0,
  height: "100%",
  overflowY: "auto",
});

function groupTasksByAssignee(
  tasks: ListTasksSlimTaskType[],
): Record<string, ListTasksSlimTaskType[]> {
  const grouped: Record<string, ListTasksSlimTaskType[]> = {};

  for (const task of tasks) {
    if (task.assigneeUserId == null) {
      if (grouped._unassigned == null) {
        grouped._unassigned = [];
      }
      grouped._unassigned.push(task);
    } else {
      if (grouped[task.assigneeUserId] == null) {
        grouped[task.assigneeUserId] = [];
      }
      grouped[task.assigneeUserId].push(task);
    }
  }

  return grouped;
}

export const SprintPlanningSprintView = React.memo(
  function SprintPlanningSprintView(props: {
    sprintUUID: string;
    teamUUID: string;
  }): JSX.Element {
    const [rollTasksSprint, setRollTasksSprint] = React.useState<string | null>(
      null,
    );
    const [duration, setDuration] = React.useState(7);
    const teamQuery = useGetTeam({ teamUUID: props.teamUUID });
    const sprintQuery = seamlessClient.useQuery<GetSprintBP.Plug>(
      GetSprintBP,
      { sprintUUID: props.sprintUUID },
    );
    const tasksInSprint = useTasksInSprint(props.sprintUUID);
    const sprintsForTeam = useGetSprintsForTeam({ teamUUID: props.teamUUID });
    const addTaskToSprintMutation =
      seamlessClient.useMutation<AddTaskToSprintBP.Plug>(
        AddTaskToSprintBP,
      );
    const removeTaskFromSprintMutation =
      seamlessClient.useMutation<RemoveTaskFromSprintBP.Plug>(
        RemoveTaskFromSprintBP,
      );
    const startSprintMutation =
      seamlessClient.useMutation<StartSprintBP.Plug>(StartSprintBP, {
        querySideEffects: [sprintQuery],
      });
    const endSprintMutation =
      seamlessClient.useMutation<EndSprintBP.Plug>(EndSprintBP, {
        querySideEffects: [sprintQuery],
      });
    const sprint = sprintQuery.data?.sprint ?? null;
    const [selectedTaskUUID, setSelectedTaskUUID] = React.useState<
      string | null
    >(null);

    const addTaskToSprint = React.useCallback(
      (taskUUID: string) => {
        void addTaskToSprintMutation.call({
          sprintUUID: props.sprintUUID,
          taskUUID,
        });
      },
      [addTaskToSprintMutation, props.sprintUUID],
    );

    const removeTaskFromSprint = React.useCallback(
      (taskUUID: string) => {
        void removeTaskFromSprintMutation.call({
          sprintUUID: props.sprintUUID,
          taskUUID,
        });
      },
      [props.sprintUUID, removeTaskFromSprintMutation],
    );

    const startSprint = React.useCallback(() => {
      void startSprintMutation.call({
        sprintUUID: props.sprintUUID,
        timing: {
          duration: { workDays: duration },
          startDate: new Date(),
          endDate: moment().add(duration, "days").toDate(),
        },
      });
    }, [duration, props.sprintUUID, startSprintMutation]);

    const endSprint = React.useCallback(() => {
      void endSprintMutation.call({ sprintUUID: props.sprintUUID });
    }, [props.sprintUUID, endSprintMutation]);

    const totalWork = getTotalSizingInDays(tasksInSprint.data);
    const remainingWork = getTotalSizingInDays(
      tasksInSprint.data.filter(
        (t) => t.status.closed !== true,
      ),
    );
    const groupedTasks = groupTasksByAssignee(tasksInSprint.data);
    const tasksAttachedToSprint = tasksInSprint.data.filter(
      (t) => t.sprintUUID === props.sprintUUID,
    );
    const tasksWithWarnings = tasksInSprint.data.filter(
      (task) => getTaskWarning(task) != null,
    );
    const navigate = useNavigate();
    const newSprintWithRoll = useNavigateTo({
      feature: Features.tasks,
      params: {
        view: "create-sprint",
        teamUUID: props.teamUUID,
        rolloverFromSprint: props.sprintUUID,
      },
    });
    const moveUnfinishedTasksToDifferentSprintMutation =
      useMoveUnfinishedTasksToDifferentSprint();

    const sortedTasks = React.useMemo(() => {
      return tasksInSprint.data.sort((a, b) => {
        return (
          a.priority.order - b.priority.order ||
          (a.dueDate?.date.valueOf() ?? Infinity) -
          (b.dueDate?.date.valueOf() ?? Infinity)
        );
      });
    }, [tasksInSprint.data]);

    const rollTasks = React.useCallback(() => {
      if (rollTasksSprint == null) {
        return newSprintWithRoll();
      } else {
        void moveUnfinishedTasksToDifferentSprintMutation
          .call({
            fromSprintUUID: props.sprintUUID,
            toSprintUUID: rollTasksSprint,
          })
          .then(() => {
            navigate({
              feature: Features.tasks,
              params: {
                view: "sprint-planning",
                sprintUUID: rollTasksSprint,
                teamUUID: props.teamUUID,
              },
            });
          });
      }
    }, [
      moveUnfinishedTasksToDifferentSprintMutation,
      navigate,
      newSprintWithRoll,
      props.sprintUUID,
      props.teamUUID,
      rollTasksSprint,
    ]);

    const incompleteTasks = tasksAttachedToSprint.filter(
      (t) => t.status.closed !== true,
    );

    return (
      <>
        {selectedTaskUUID != null ? (
          <Modal onClose={() => setSelectedTaskUUID(null)}>
            <TaskView uuid={selectedTaskUUID} />
          </Modal>
        ) : null}
        <SprintPlanningContainer>
          <SprintPlanningLeftColumn>
            <SprintPlanningSprintName>
              {sprintQuery.isLoading || sprintQuery.isError ? (
                <LoadingSpinner />
              ) : (
                sprintQuery.data.sprint.name
              )}
            </SprintPlanningSprintName>
            <Spacer height={7.5} />
            <SprintPlanningTeamName>
              {teamQuery.isLoading || teamQuery.isError ? (
                <LoadingSpinner />
              ) : (
                teamQuery.data.team.name
              )}
            </SprintPlanningTeamName>

            <Spacer height={30} />

            <DetailRow>
              <FontAwesomeIcon icon={faCalendarDays} fixedWidth />
              <div>
                {Math.ceil(totalWork)} work-day
                {Math.ceil(totalWork) === 1 ? "" : "s"} of tasks
              </div>
            </DetailRow>
            <Spacer height={10} />
            <DetailRow>
              <FontAwesomeIcon icon={faChartLineDown} fixedWidth />
              <div>
                {formatEstimateFromWorkDays(remainingWork)} of tasks remaining
              </div>
            </DetailRow>
            <Spacer height={10} />
            {sprint?.timing != null &&
              sprint.status === SprintStatusEnum.active ? (
              <DetailRow>
                <FontAwesomeIcon icon={faAlarmClock} fixedWidth />
                <div>Ends {moment(sprint.timing.endDate).fromNow()}</div>
              </DetailRow>
            ) : null}
            {sprint?.status === SprintStatusEnum.completed ? (
              <DetailRow>
                <FontAwesomeIcon icon={faCheck} fixedWidth />
                <div>Sprint Completed</div>
              </DetailRow>
            ) : null}
            <Spacer height={7.5} />
            {tasksWithWarnings.length !== 0 ? (
              <WarningPill>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                {tasksWithWarnings.length} task
                {tasksWithWarnings.length === 1 ? "" : "s"} with warnings
              </WarningPill>
            ) : null}
            <Spacer height={30} />
            {sprint == null ? (
              <LoadingSpinner />
            ) : sprint.status === SprintStatusEnum.active ? (
              <div>
                <Button
                  onClick={endSprint}
                  autoWidth
                  isLoading={endSprintMutation.isLoading}
                  label={
                    <>
                      <FontAwesomeIcon icon={faRunning} fixedWidth />
                      <div>End Sprint</div>
                    </>
                  }
                />
              </div>
            ) : sprint.status === SprintStatusEnum.completed ? (
              incompleteTasks.length > 0 ? (
                <div>
                  <div
                    style={{
                      paddingBottom: 10,
                      maxWidth: 300,
                    }}
                  >
                    You have {incompleteTasks.length} unfinished task
                    {incompleteTasks.length === 1 ? "" : "s"} from this sprint.
                    Roll them to a another one?
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    {sprintsForTeam.isLoading || sprintsForTeam.isError ? (
                      <LoadingSpinner />
                    ) : (
                      <Select
                        options={[
                          {
                            label: "New Sprint",
                            value: null,
                          },
                          ...sprintsForTeam.data.sprints
                            .filter((s) => s.uuid !== props.sprintUUID)
                            .map((s) => ({
                              label: s.name,
                              value: s.uuid,
                            })),
                        ]}
                        value={rollTasksSprint}
                        onChangeValue={(value) =>
                          setRollTasksSprint(value as string)
                        }
                      />
                    )}
                    <CircleButton
                      onClick={rollTasks}
                      size={35}
                      isLoading={
                        moveUnfinishedTasksToDifferentSprintMutation.isLoading
                      }
                    />
                  </div>
                </div>
              ) : null
            ) : (
              <>
                <Row>
                  <Select
                    value={duration}
                    onChangeValue={(value) => setDuration(value as number)}
                    options={SPRINT_DURATION_OPTIONS}
                  />
                  <Button
                    onClick={startSprint}
                    label="Start Sprint"
                    isLoading={startSprintMutation.isLoading}
                  />
                </Row>
                <Spacer height={15} />
                <div>
                  Sprint will end{" "}
                  {moment().add(duration, "days").format("dddd, MMM Do")}
                </div>
              </>
            )}
          </SprintPlanningLeftColumn>
          <TasksColumn style={{ flexGrow: 1 }}>
            <AddExistingTaskView onTaskSelected={addTaskToSprint} sprintUUID={props.sprintUUID} />
            {sortedTasks.map((task) => (
              <SprintPlanningTaskCard
                onClick={() => setSelectedTaskUUID(task.uuid)}
                task={task}
                key={task.uuid}
                onRemoveFromSprint={() => removeTaskFromSprint(task.uuid)}
              />
            ))}
          </TasksColumn>
          <TasksColumn>
            {Object.entries(groupedTasks).map(([assigneeUserId, tasks]) => (
              <ICSummary
                key={assigneeUserId}
                userId={assigneeUserId}
                tasks={tasks}
              />
            ))}
          </TasksColumn>
        </SprintPlanningContainer>
      </>
    );
  },
);

export const SprintPlanningView = React.memo(
  function SprintPlanningView(props: {
    teamUUID: string;
    sprintUUID: string | null;
  }): JSX.Element {
    if (props.sprintUUID == null) {
      return <SprintPlanningListView teamUUID={props.teamUUID} />;
    }

    return (
      <SprintPlanningSprintView
        sprintUUID={props.sprintUUID}
        teamUUID={props.teamUUID}
      />
    );
  },
);
