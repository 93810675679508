import { faFilterSlash } from "@fortawesome/pro-light-svg-icons";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimateChangeInWidth, MouseInteraction } from "@hiyllo/ux/animation";
import { useWrappedPopOver } from "@hiyllo/ux/context-menu";
import { Select } from "@hiyllo/ux/select";
import { styled } from "@hiyllo/ux/styled";
import React from "react";

const StaticElementContainer = styled<"div", { active: boolean; }>("div", ({ $theme, active }) => ({
  background: active ? $theme.buttonBackground : $theme.background3,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
  fontFamily: 'hiyllo',
  // width: "max-content",
  fontSize: 12.5,
  paddingLeft: 10,
  paddingRight: 10,
  height: 30,
  borderRadius: 5,
  cursor: "pointer",
  whiteSpace: "nowrap",
  userSelect: "none",
}));

const FilterOptionsContainer = styled("div", ({ $theme }) => ({
  borderRadius: 5,
  backgroundColor: $theme.background3,
  padding: 7.5,
  display: "flex",
  flexDirection: "column",
  gap: 10,
  whiteSpace: "nowrap",
  boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 20px 0px",
  userSelect: "none",
}));

const OptionContainer = styled<"div", { $selected: boolean; }>("div", ({ $theme, $selected }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
  paddingLeft: 5,
  paddingRight: 5,
  height: 25,
  borderRadius: 5,
  cursor: "pointer",
  backgroundColor: $selected ? $theme.midground1 : "transparent",
  // color: $selected ? $theme.buttonText : $theme.text,
  fontFamily: 'hiyllo',
  fontSize: 12.5,
  whiteSpace: "nowrap",
  userSelect: "none",
}));

const FilterControlRaw = (function FilterControl<T extends string | null | number>(props: {
  value: T | null;
  options: NonNullable<T>[];
  onChangeValue: (value: T | null) => void;
  renderOption: (option: NonNullable<T>) => string | React.ReactNode;
  noValueElement: React.ReactNode;
}): JSX.Element {
  const cxMenu = useWrappedPopOver<HTMLDivElement>({
    displayMode: "fixed",
    offset: { top: 35 },
    disableMaxHeight: true,
    fadeAnimation: true,
  });

  return (
    <>
      <StaticElementContainer _ref={cxMenu.ref} active={props.value !== null} onClick={cxMenu.open}>
        {props.value == null ? props.noValueElement : props.renderOption(props.value)}
        <FontAwesomeIcon icon={faCaretDown} />
      </StaticElementContainer>
      <cxMenu.CXMenuContainer>
        <FilterOptionsContainer>
          <MouseInteraction>
            <OptionContainer $selected={props.value == null} onClick={() => props.onChangeValue(null)}>
              <FontAwesomeIcon icon={faFilterSlash} />
              No Filter
            </OptionContainer>
          </MouseInteraction>
          {props.options.map((option) => (
            <MouseInteraction key={option}>
              <OptionContainer $selected={option === props.value} onClick={() => props.onChangeValue(option)}>
                {props.renderOption(option)}
              </OptionContainer>
            </MouseInteraction>
          ))}
        </FilterOptionsContainer>
      </cxMenu.CXMenuContainer>
    </>
  );
});

export const FilterControl = React.memo(FilterControlRaw) as typeof FilterControlRaw;