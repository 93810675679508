/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TimecardType } from "../../types/talent/timecard";

export type ParamsType = null;
export type ResponseType = {
  timecards: TimecardType[];
};
export const Endpoint = "talent/get-all-timecards";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}