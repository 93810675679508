import React from "react";
import sanitizeHTML from "sanitize-html";
import DOMPurify from "dompurify";
import Juice from "juice";

const allowedTags = [
  "address",
  "article",
  "aside",
  "footer",
  "font",
  "header",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "hgroup",
  "main",
  "nav",
  "section",
  "blockquote",
  "dd",
  "div",
  "dl",
  "dt",
  "figcaption",
  "figure",
  "hr",
  "li",
  "main",
  "ol",
  "p",
  "pre",
  "ul",
  "a",
  "abbr",
  "b",
  "bdi",
  "bdo",
  "br",
  "cite",
  "code",
  "data",
  "dfn",
  "em",
  "i",
  "kbd",
  "mark",
  "q",
  "rb",
  "rp",
  "rt",
  "rtc",
  "ruby",
  "s",
  "samp",
  "small",
  "span",
  "strong",
  "sub",
  "sup",
  "time",
  "u",
  "var",
  "wbr",
  "caption",
  "col",
  "colgroup",
  "table",
  "tbody",
  "td",
  "tfoot",
  "th",
  "thead",
  "tr",
  "img",
  "html",
  "head",
  "body",
  "style",
];

function sanitize(content: string): string {
  return sanitizeHTML(content, {
    allowedTags,
    allowedAttributes: {
      "*": ["style", "class"],
      a: ["href", "name", "target", "rel"],
      // We don't currently allow img itself by default, but
      // these attributes would make sense if we did.
      img: ["src", "srcset", "alt", "title", "width", "height", "loading"],
    },
    allowedSchemes: ["http", "https", "mailto", "data", "data:image"],
    allowedSchemesByTag: {
      img: ["http", "https", "data"],
    },
    parseStyleAttributes: false,
    disallowedTagsMode: "completelyDiscard",
    transformTags: {
      a: sanitizeHTML.simpleTransform("a", {
        target: "_blank",
        rel: "noopener noreferrer",
      }),
    },
  });
}

export const RenderAlreadySafe = React.memo(function RenderSafe(props: {
  content: string;
}): JSX.Element {
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const createResizeObserver = React.useCallback(() => {
    if (iframeRef.current) {
      const resize = (): void => {
        if (iframeRef.current) {
          const iframe = iframeRef.current;
          if (iframe.contentWindow) {
            iframe.style.height = `${Math.max(
              iframe.contentWindow.document.documentElement.scrollHeight,
              iframe.contentWindow.document.documentElement.clientHeight,
            ).toString() ?? ""
              }px`;
            iframe.style.opacity = "1";
            iframe.style.display = "block";
          }
        }
      };

      const observer = new MutationObserver(resize);

      observer.observe(iframeRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
      });

      resize();
      setTimeout(resize, 300);
      window.requestAnimationFrame(resize);
      window.requestIdleCallback(resize);
    }
  }, []);

  React.useEffect(() => {
    const inv = setInterval(() => {
      if (iframeRef.current?.contentWindow) {
        console.log(">>> interval");
        createResizeObserver();
        clearInterval(inv);
      }
    }, 100);

    return () => clearInterval(inv);
  }, [createResizeObserver]);

  return (
    <iframe
      sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      srcDoc={props.content}
      style={{
        width: "100%",
        overflow: "hidden",
        border: 0,
        background: "black",
        height: 0,
        opacity: 0,
        display: "none",
      }}
      ref={iframeRef}
    />
  );
});

export const RenderSafe = React.memo(function RenderSafe(props: {
  content: string;
}): JSX.Element {
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const createResizeObserver = React.useCallback(() => {
    if (iframeRef.current) {
      const resize = (): void => {
        if (iframeRef.current) {
          const iframe = iframeRef.current;
          if (iframe.contentWindow) {
            iframe.style.height = `${Math.max(
              iframe.contentWindow.document.documentElement.scrollHeight,
              iframe.contentWindow.document.documentElement.clientHeight,
            ).toString() ?? ""
              }px`;
            iframe.style.opacity = "1";
            iframe.style.display = "block";
          }
        }
      };

      const observer = new MutationObserver(resize);

      observer.observe(iframeRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
      });

      resize();
      window.requestAnimationFrame(resize);
      window.requestIdleCallback(resize);
    }
  }, []);

  const sanitized = React.useMemo(() => {
    let content = props.content.trim();

    if (!(content.startsWith("<") && content.endsWith(">"))) {
      content = `<div style="white-space: pre-wrap;">` + content + "</div>";
    }

    const htmlContent = sanitize(content);

    const purified = DOMPurify.sanitize(htmlContent, {
      WHOLE_DOCUMENT: true,
      ADD_ATTR: ["target"],
    });

    const juiced = Juice(purified);

    const juicedBody =
      juiced.match(/<body[^>]*>([\s\S]*?)<\/body>/i)?.[1] ?? "";
    const juicedDiv = `<div${juiced.match(/<body([^>]*)>/i)?.[1] ?? ""
      }>${juicedBody}</div>`;
    return juicedDiv;
  }, [props.content]);

  return (
    <iframe
      sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      srcDoc={sanitized}
      style={{
        width: "100%",
        overflow: "hidden",
        border: 0,
        background: "black",
        height: 0,
        opacity: 0,
        display: "none",
      }}
      ref={iframeRef}
      // eslint-disable-next-line react/no-unknown-property
      onLoad={createResizeObserver}
    />
  );
});
