import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { useGetProject } from "../hooks/use-get-project";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { type TaskProject } from "../../../types/tasks/tasks-organization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faPlus,
  faSquareKanban,
  faTimeline,
  faTimelineArrow,
} from "@fortawesome/pro-light-svg-icons";
import { useMultipleDocuments } from "@hiyllo/react-data";
import { seamlessClient } from "../../../seamless-client";
import { Cache } from "../../../platform/cache";

import * as ListTasksBP from "../../../blueprints/tasks/list-task-items";
import * as TasksInProjectSpec from "../../../blueprints/tasks/pubsub/tasks-in-project-spec";
import { Tenant } from "../../../platform/tenancy";
import {
  TaskType,
  type ListTasksSlimTaskType,
  type TaskSizingType,
} from "../../../types/tasks/task-item";
import { IconsByTaskType } from "../constants/icons-by-task-type";
import { Input } from "@hiyllo/ux/input";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { useCreateTaskItem } from "../hooks/use-create-task-item";
import { SizingPill } from "../components/sizing-pill";
import { useProjectTasks } from "../hooks/use-project-tasks";
import {
  FixedSizingPillContext,
  FixedStatusPillContext,
  HighlightTaskContext,
  KanbanFramelessContext,
  TaskKanbanView,
} from "../kanban-view/task-kanban-view";
import moment from "moment";
import { useProjectOrder } from "../hooks/use-project-order";
import { useUpdateTaskSizing } from "../hooks/use-update-task-sizing";
import { useUpdateTaskParent } from "../hooks/use-update-task-parent";
import { useUpdateTaskStatus } from "../hooks/use-update-task-status";
import {
  formatEstimateFromWorkDays,
  getRemainingSizingInDays,
  getTotalSizingInDays,
} from "../../../utils/tasks/sizing";
import { ProjectArchivedBanner } from "../components/project-archived-banner";
import { useShowConfirmationDialog } from "@hiyllo/ux/dialogs";
import { TaskSearchOverlay } from "@hiyllo/omni-search";
import { useDefaults } from "@hiyllo/omni-tasks/main";

const ProjectPlanningViewContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  overflowX: "auto",
  gap: 30,
  height: "100%",
}));

const ProjectName = styled("div", ({ $theme }) => ({
  fontSize: 60,
  fontWeight: 500,
  fontFamily: "hiyllo",
  wordBreak: "break-word",
}));

const ProjectColumn = styled("div", ({ $theme }) => ({
  maxWidth: 300,
  flexShrink: 0,
  // background: $theme.background2
}));

const EpicColumn = styled("div", ({ $theme }) => ({
  width: 300,
  display: "flex",
  flexDirection: "column",
  gap: 7.5,
  height: "100%",
  flexShrink: 0,
  // background: $theme.background2
}));

const Row = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 20,
  fontSize: 22,
  fontFamily: "hiyllo",
  userSelect: "none",
  // background: $theme.background2
}));

const AddCard = styled("div", ({ $theme }) => ({
  background: $theme.background2,
  padding: 15,
  borderRadius: 10,
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

const PlaceholderCard = styled("div", ({ $theme }) => ({
  background: $theme.background2,
  borderRadius: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "100%",
}));

const BottomColumnCard = styled<"div", { isHighlighted?: boolean }>(
  "div",
  ({ $theme, isHighlighted }) => ({
    background: isHighlighted === true ? $theme.midground : $theme.background2,
    cursor: "pointer",
    borderRadius: 10,
    padding: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 15,
    flexDirection: "row",
  }),
);

const InputPlaceholder = styled<"div">("div", ({ $theme }) => ({
  borderColor: $theme.background3,
  borderWidth: 2,
  borderStyle: "solid",
  cursor: "pointer",
  borderRadius: 8,
  padding: 15,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 15,
  flexDirection: "row",
}));

const AddCardInputRow = styled("div", ({ }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 15,
  alignItems: "center",
}));

const DetailRow = styled("div", ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  fontSize: 18,
}));

const ColumnIcon = styled("div", ({ fontSize: 40 }));

const ProjectPlanningView = React.memo(function ProjectPlanningView(props: {
  project: TaskProject;
  tasksList: ListTasksSlimTaskType[];
}): JSX.Element {
  return (
    <div>
      Unimplemented
    </div>
  );
});

export const ProjectPlanningViewWrapper = React.memo(
  function ProjectPlanningViewWrapper(props: {
    projectUUID: string;
  }): JSX.Element {
    const projectQuery = useGetProject({ uuid: props.projectUUID });

    const tasksList = useMultipleDocuments<
      ListTasksSlimTaskType,
      {
        subscription: TasksInProjectSpec.Typings;
        query: ListTasksBP.Plug;
      }
    >(seamlessClient, {
      cache: Cache,
      key: "tasks.for-project/" + props.projectUUID,
      subscription: {
        blueprint: TasksInProjectSpec,
        topic: `[${Tenant}]tasks-in-project/${props.projectUUID}`,
      },
      query: {
        blueprint: ListTasksBP,
        params: { projectUUID: props.projectUUID },
        mapperFn: (res) => res.tasks,
        deps: [props.projectUUID],
      },
      keyExtractorFn: (d) => d.uuid,
    });

    if (projectQuery.isError) {
      return <div>Error Loading</div>;
    }

    if (projectQuery.isLoading) {
      return <LoadingSpinnerFullView />;
    }

    return (
      <ProjectPlanningView
        project={projectQuery.data.project}
        tasksList={tasksList.data}
      />
    );
  },
);
