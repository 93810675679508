import React from "react";
import { useOwnTasks } from "@hiyllo/omni-tasks";
import { useSelf } from "@hiyllo/omni-continuity";

import { TasksView } from "./tasks-view";
import { TabDetails } from "../../tokyo/tabbing/tabs-provider";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";

export const TasksICView = React.memo(function TaskGroupView(): JSX.Element {
  const self = useSelf();
  const { tasks, order, onChangeOrder } = useOwnTasks({ filter: null }, self);

  if (order === null) {
    return <LoadingSpinnerFullView />;
  }

  return (
    <TabDetails label="Your Tasks">
      <TasksView
        order={order}
        onChangeOrder={onChangeOrder}
        tasks={tasks.data}
        isReady={!tasks.queryLoading}
        cacheKey="icview"
      />
    </TabDetails>
  );
});
