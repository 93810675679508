import React from "react";
import { CommandPalette } from "../features/command-palette/command-palette";

export interface CommandPaletteOptions {
  openedViaButton?: boolean;
}

interface CommandPaletteContextType {
  set: (value: true | CommandPaletteOptions | null) => void;
}

const CommandPaletteContext =
  React.createContext<CommandPaletteContextType | null>(null);

export function useCommandPalette(): CommandPaletteContextType {
  const value = React.useContext(
    CommandPaletteContext,
  );

  if (value == null) {
    throw new Error("useCommandPalette must be used within a CommandPaletteProvider");
  }

  return value;
}

export const CommandPaletteProvider = React.memo(function CommandPaletteProvider(
  props: React.PropsWithChildren,
): JSX.Element {
  console.debug('Rendering <CommandPaletteProvider>');

  const [showCommandPalette, setShowCommandPalette] = React.useState<true | CommandPaletteOptions | null>(null);

  const set = React.useCallback((v: true | CommandPaletteOptions | null) => {
    setShowCommandPalette(v);
  }, []);

  React.useEffect(() => {
    const onKeyDown = (evt: KeyboardEvent): void => {
      if ((evt.metaKey || evt.ctrlKey) && evt.key === "/") {
        evt.preventDefault();
        evt.stopPropagation();
        setShowCommandPalette(v => v == null ? true : null);
      }
    };

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <CommandPaletteContext.Provider
      value={{
        set
      }}
    >
      {showCommandPalette != null ? (
        <>
          <CommandPalette onClose={() => setShowCommandPalette(null)} options={showCommandPalette} />
        </>
      ) : null}
      {props.children}
    </CommandPaletteContext.Provider>
  );
});
