/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export enum TimecardStatusEnum {
    clockedIn = "clockedIn",
    clockedOut = "clockedOut",
    paid = "paid"
}

export interface TimecardType {
    userUUID: string;
    uuid: string;
    clockIn: Date;
    clockOut: Date | null;
    recordedHours: number | null;
    hourlyRateE2: number;
    amountPayableE2: number | null;
    status: TimecardStatusEnum;
    markedPaidBy: string | null;
    markedPaidOn: Date | null;
}