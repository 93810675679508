import { Electron } from "../electron";

export function getRootURL(): string {
  const EWindow: Window & { seamlessURL?: string } = window;


  if (Electron.electronHost != null) {
    if (Electron.electronHost === "localhost:3001") {
      return "http://localhost:3001";
    }

    return "https://" + Electron.electronHost;
  }

  if (Electron.isElectron) {
    return "https://www.hiyllo.work";
  }

  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    return "http://localhost:3001";
  }

  return window.location.origin;
}

export function isDesktopApp(): boolean {
  const EWindow: Window & { seamlessURL?: string } = window;
  return (
    ("seamlessURL" in EWindow && typeof EWindow.seamlessURL === "string") ||
    window.localStorage.electronTenant != null
  );
}
