import React from "react";
import { Input } from "@hiyllo/ux/input";
import { NymblIcon } from "@hiyllo/icons";
import { styled } from "@hiyllo/ux/styled";
import { useNavigateTo } from "@hiyllo/omni-router";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { AnimatePresence, motion } from "framer-motion";
import { useWrappedPopOver } from "@hiyllo/ux/context-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRefreshSelf, useSelf } from "@hiyllo/omni-continuity";
import { Features } from "@hiyllo/omni-common/src/types/navigation/features";
import { type UserStatusType } from "@hiyllo/omni-common/src/types/accounts/user";
import { type IconDefinition, faCheck, faGears, faLock, faSmile, faInfoCircle } from "@fortawesome/pro-light-svg-icons";

import * as SetStatusBP from "../../../../blueprints/accounts/user/set-status";
import * as RetrieveEventBP from "../../../../blueprints/calendar/retrieve-event";

import { SelfImage } from "./self-image";
import { MuteController } from "./mute-controller";
import { Electron } from "../../../../platform/electron";
import { EmojiPicker } from "../../../../ux/emoji-picker";
import { seamlessClient } from "../../../../seamless-client";
import { useOnClickOutV2 } from "../../../../ux/alpha/utils";
import { useIsSolo } from "../../../../platform/hooks/use-is-solo";
import { logout } from "../../../../platform/accounts/methods/logout";
import { Emoji } from "@hiyllo/omni-emoji";
import { useAlertDialog } from "@hiyllo/ux/dialogs";
import { MoopsyError } from "@moopsyjs/core/main";

const StatusSelectContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: 10,
});

const StatusSelectEmojiButton = styled("div", ({ $theme }) => ({
  backgroundColor: $theme.midground,
  height: 40,
  width: 40,
  cursor: "pointer",
  userSelect: "none",
  flexShrink: 0,
  borderRadius: 40 / 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const EmojiButton = React.memo(function EmojiButton(props: {
  onSelectEmoji: (native: string) => void;
  selectedEmoji: string | null;
}): JSX.Element {
  const { open, close, ref, CXMenuContainer, midFoldRelative } =
    useWrappedPopOver({

      offset: {
        x: -80,
        y: 0,
      },
      displayMode: "absolute",
      disableMaxHeight: true,
    });

  return (
    <>
      <StatusSelectEmojiButton onClick={open} _ref={ref as React.RefObject<HTMLDivElement>} className="StatusSelectEmojiButton">
        {props.selectedEmoji != null ? <Emoji emoji={props.selectedEmoji} size={15} /> : <FontAwesomeIcon icon={faSmile} />}
      </StatusSelectEmojiButton>
      <CXMenuContainer>
        <div style={{}}>
          <div
            style={
              midFoldRelative === "above"
                ? {}
                : {
                  position: "absolute",
                  left: 5,
                  bottom: -40,
                }
            }
          >
            <EmojiPicker
              onEmojiSelect={(evt: { native: string }) => {
                props.onSelectEmoji(evt.native);
                close();
              }}
            />
          </div>
        </div>
      </CXMenuContainer>
    </>
  );
});

const SidebarTakoverContainer = styled<"div", { showSidebar: boolean }>(
  "div",
  ({ $theme, showSidebar }) => ({
    position: "absolute",
    bottom: 0,
    left: 80,
    width: 280,
    height: Electron.isElectron ? "calc(100% - 40px)" : "100%",
    zIndex: 3,
    pointerEvents: showSidebar ? "auto" : "none",
    color: $theme.foreground,
  }),
);

const SidebarTakeoverInner = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "flex-start",
  height: "calc(100% - 40px)",
  width: 280 - 40,
  background: $theme.background3,
  padding: 20,
}));

const BottomRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  gap: 10,
});

const Name = styled("div", { fontSize: 18, fontWeight: "bold" });
const UserName = styled("div", { fontSize: 14 });
const SideButtonRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 12.5,
  cursor: "pointer",
  userSelect: "none",
});

const SideButton = React.memo(function SideButton(props: {
  icon: IconDefinition;
  label: string;
  onClick: () => void;
}): JSX.Element {
  return (
    <SideButtonRow onClick={props.onClick}>
      <div
        style={{
          width: 40,
          height: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // background: "red",
        }}
      >
        <FontAwesomeIcon icon={props.icon} fixedWidth />
      </div>
      <div style={{ fontFamily: "hiyllo" }}>{props.label}</div>
    </SideButtonRow>
  );
});

const DerivedFromCalendarEvent = React.memo(function DerivedFromCalendarEvent(props: {
  eventUUID: string;
}): JSX.Element | null {
  const self = useSelf();
  const event = seamlessClient.useQuery<RetrieveEventBP.Plug>(RetrieveEventBP, { eventUUID: props.eventUUID });

  if (event.isLoading || event.isError) {
    return null;
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: 10, paddingLeft: 5, paddingRight: 5 }}>
      <div style={{ fontFamily: "hiyllo" }}>
        <div style={{ fontSize: 12.5, display: "flex", flexDirection: "row", alignItems: "flex-start", gap: 10 }}>
          <div style={{ flexShrink: 0, paddingTop: 2.5 }}>
            <NymblIcon color="white" width={12.5} />
          </div>
          <div>I updated your status for you since you have {event.data.event.title} on your calendar right now.{self.status?.restore?.message != null ? ` I'll change it back to "${self.status?.restore?.message}" afterwards.` : " I'll remove your status afterwards."}</div>
        </div>
      </div>
    </div>
  );
});

export const SidebarTakeover = React.memo(function SidebarTakover(props: {
  showSidebar: boolean;
  onCloseSidebar: () => void;
}): JSX.Element {
  const self = useSelf();
  const ref = useOnClickOutV2(props.onCloseSidebar);

  const navigateToProfile = useNavigateTo({
    feature: Features.profile,
    params: { view: "profile" },
  });

  const onClickProfile = React.useCallback(() => {
    navigateToProfile();
    props.onCloseSidebar();
  }, [navigateToProfile, props]);

  const refreshSelf = useRefreshSelf();
  const setStatusMutation =
    seamlessClient.useMutation<SetStatusBP.Plug>(SetStatusBP, {
      querySideEffects: [],
    });
  const [selectedEmoji, setSelectedEmoji] = React.useState<string | null>(
    self.status?.emoji ?? null,
  );
  const [typedStatus, setTypedStatus] = React.useState<string>(
    self.status?.message ?? "",
  );
  const currentStatus = self.status ?? {
    emoji: null,
    message: "",
    expires: null,
  };
  const statusChanged =
    selectedEmoji !== self.status?.emoji ||
    typedStatus !== currentStatus.message;
  const statusCanBeSaved = selectedEmoji != null && typedStatus.length > 0;
  const showAlert = useAlertDialog();

  const saveStatus = React.useCallback(() => {
    if (!statusCanBeSaved) {
      return;
    }

    const status: UserStatusType = {
      emoji: selectedEmoji,
      message: typedStatus,
      expires: null,
    };
    void setStatusMutation
      .call({
        status,
      })
      .then(refreshSelf).catch(err => {
        void showAlert({
          title: "Error updating status",
          message: (err as MoopsyError).message,
        });
      });
  }, [statusCanBeSaved, selectedEmoji, typedStatus, setStatusMutation, refreshSelf, showAlert]);
  const isSolo = useIsSolo();

  return (
    <SidebarTakoverContainer showSidebar={props.showSidebar} _ref={ref}>
      <motion.div
        initial={{ width: "0%" }}
        animate={{
          width: props.showSidebar ? "100%" : "0%",
        }}
        style={{ height: "100%", overflow: "hidden" }}
      >
        <SidebarTakeoverInner>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              width: "100%",
            }}
          >
            <SideButton
              icon={faInfoCircle}
              label="About"
              onClick={() => {
                window.location.href = "/about";
              }}
            />
            <SideButton icon={faLock} label="Logout" onClick={logout} />
            <SideButton icon={faGears} label="Settings" onClick={onClickProfile} />
            {!isSolo ? (
              <>
                <StatusSelectContainer>
                  <EmojiButton
                    onSelectEmoji={setSelectedEmoji}
                    selectedEmoji={selectedEmoji}
                  />
                  <Input
                    placeholder="What's going on...?"
                    onChangeValue={setTypedStatus}
                    value={typedStatus}
                  />
                  {statusChanged && statusCanBeSaved ? (
                    <div className="status-save-button">
                      <CircleButton
                        onClick={saveStatus}
                        size={40}
                        icon={faCheck}
                        isLoading={setStatusMutation.isLoading}
                      />
                    </div>
                  ) : null}
                </StatusSelectContainer>
                {self.status?.derivedFrom != null ?
                  self.status.derivedFrom.type === 'calendar-event' ?
                    <DerivedFromCalendarEvent eventUUID={self.status.derivedFrom.eventUUID} />
                    : null
                  : null}
              </>
            ) : null}
            <div style={{ height: 20 }} />
          </div>
          <BottomRow>
            <AnimatePresence>
              {props.showSidebar ? <SelfImage /> : null}
            </AnimatePresence>
            <div style={{ fontFamily: "hiyllo" }}>
              <Name>{self.profile?.name}</Name>
              {self.username ? <UserName>@{self.username}</UserName> : null}
            </div>
          </BottomRow>
        </SidebarTakeoverInner>
      </motion.div>
    </SidebarTakoverContainer>
  );
});
