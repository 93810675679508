/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { UserType } from "../../../types/accounts/user";

export type ParamsType = {
  userId: string;
} | {
  userUUID: string;
};
export type ResponseType = {
  user: UserType;
};
export const Endpoint = "admin/user-management/get-user-admin";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"anyOf":[{"type":"object","properties":{"userId":{"type":"string"}},"required":["userId"]},{"type":"object","properties":{"userUUID":{"type":"string"}},"required":["userUUID"]}],"$schema":"http://json-schema.org/draft-07/schema#"}