/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TaskPriorityV3, TaskStatusV3 } from "../../../types/tasks/tasks-organization";

export type ParamsType = { projectUUID: string; };
export type ResponseType = {
  priorities: TaskPriorityV3[] | null;
  statuses: TaskStatusV3[] | null;
};
export const Endpoint = 'tasks/projects/list-project-defaults';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 20,
  per: 500,
};export const paramsSchema = {"type":"object","properties":{"projectUUID":{"type":"string"}},"required":["projectUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}