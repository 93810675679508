import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { useGetTaskActivity } from "../hooks/use-get-task-activity";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import {
  type AssignmentActivity as AssignmentActivityType,
  type DueDateChangeActivity as DueDateChangeActivityType,
  type StatusChangeActivity as StatusChangeActivityType,
  type CommentTaskActivityType,
  type TaskActivityType,
  type TitleChangeActivity as TitleChangeActivityType,
  type DescriptionChangeActivity as DescriptionChangeActivityType,
  type SizingChangeActivity as SizingChangeActivityType,
  type ProjectChangeActivity as ProjectChangeActivityType,
  TaskItem,
  EnhancedTaskItem,
} from "../../../types/tasks/task-item";
import { UserImage } from "@hiyllo/omni-images/main";
import moment from "moment";
import { Input } from "@hiyllo/ux/input";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { useAddCommentToTask } from "../hooks/use-add-comment-to-task";
import { formatSizing } from "../components/sizing-pill";
import { useGetProject } from "../hooks/use-get-project";
import { StatusPill } from "@hiyllo/omni-tasks";
import { useLookupByUserId } from "@hiyllo/omni-continuity/main";
import { Button, ButtonVariant } from "@hiyllo/ux/button";

const ActivityItemContainer = styled("div", { width: "100%" });

const Container = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  width: 280,
  flexShrink: 0,
  color: $theme.foreground,
  pointerEvents: "auto",
  paddingTop: 40,
  paddingBottom: 40,
  overflowX: "hidden",
}));

const TaskActivityItemRow = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 10,
  color: $theme.foreground,
}));

const Title = styled("div", { fontSize: 16 });

const TitleRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
  gap: 20,
});

const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 10,
  alignItems: "center",
});

const HeaderBlock = React.memo(function HeaderBlock(props: {
  title: string;
  actor: string;
  date: Date;
}): JSX.Element {
  return (
    <div style={{ marginBottom: 5 }}>
      <TitleRow>
        <Title>{props.title}</Title>
      </TitleRow>
    </div>
  );
});

const CommentActivity = React.memo(function CommentActivity(props: {
  item: CommentTaskActivityType;
}): JSX.Element {
  return (
    <div>
      <div style={{ marginTop: 2.5 }}>{props.item.content.text}</div>
    </div>
  );
});

const AssignmentActivity = React.memo(function CommentActivity(props: {
  item: AssignmentActivityType;
}): JSX.Element {
  return (
    <div>
      <HeaderBlock
        title="Assignee Changed"
        actor={props.item.actor}
        date={props.item.date}
      />
      <Row>
        {props.item.previousAssignee ? (
          <UserImage userId={props.item.previousAssignee} width={25} />
        ) : (
          "Unassigned"
        )}
        <div>to</div>
        {props.item.newAssignee ? (
          <UserImage userId={props.item.newAssignee} width={25} />
        ) : (
          "Unassigned"
        )}
      </Row>
    </div>
  );
});

const DueDateChangeActivity = React.memo(function CommentActivity(props: {
  item: DueDateChangeActivityType;
}): JSX.Element {
  return (
    <ActivityItemContainer>
      <HeaderBlock
        title="Due Date Changed"
        actor={props.item.actor}
        date={props.item.date}
      />
      <Row>
        {props.item.oldDueDate != null
          ? moment(props.item.oldDueDate.date).format("ddd, MMM Do")
          : "None"}
        <div>to</div>
        {props.item.newDueDate != null
          ? moment(props.item.newDueDate.date).format("ddd, MMM Do")
          : "None"}
      </Row>
    </ActivityItemContainer>
  );
});

const StatusChangeActivity = React.memo(function StatusChangeActivity(props: {
  item: StatusChangeActivityType;
  task: TaskItem;
}): JSX.Element {
  if (!("uuid" in props.item.newStatus) || !("uuid" in props.item.previousStatus)) {
    return <></>;
  }

  return (
    <ActivityItemContainer>
      <HeaderBlock
        title="Status Changed"
        actor={props.item.actor}
        date={props.item.date}
      />
      <Row>
        <StatusPill status={props.item.previousStatus} onChangeStatus={null} projectUUID={props.task.projectUUID} />
        <div>to</div>
        <StatusPill status={props.item.newStatus} onChangeStatus={null} projectUUID={props.task.projectUUID} />
      </Row>
    </ActivityItemContainer>
  );
});

const ProjectChangeActivity = React.memo(function StatusChangeActivity(props: {
  item: ProjectChangeActivityType;
}): JSX.Element {
  const oldProject = useGetProject({ uuid: props.item.oldProjectUUID });
  const newProject = useGetProject({ uuid: props.item.newProjectUUID });

  return (
    <ActivityItemContainer>
      <div>
        Moved from {oldProject?.data?.project.name ?? "???"} to{" "}
        {newProject?.data?.project.name ?? "???"}
      </div>
    </ActivityItemContainer>
  );
});

const TitleChangeActivity = React.memo(function StatusChangeActivity(props: {
  item: TitleChangeActivityType;
}): JSX.Element {
  return (
    <ActivityItemContainer>
      <HeaderBlock
        title="Title Changed"
        actor={props.item.actor}
        date={props.item.date}
      />
      <Row>
        <div>{props.item.newTitle}</div>
      </Row>
    </ActivityItemContainer>
  );
});

const DescriptionChangeActivity = React.memo(
  function StatusChangeActivity(props: {
    item: DescriptionChangeActivityType;
  }): JSX.Element {
    return (
      <ActivityItemContainer>
        <HeaderBlock
          title="Description Changed"
          actor={props.item.actor}
          date={props.item.date}
        />
        <Row>
          <div>{props.item.newDescription}</div>
        </Row>
      </ActivityItemContainer>
    );
  },
);

const SizingChangeActivity = React.memo(function StatusChangeActivity(props: {
  item: SizingChangeActivityType;
}): JSX.Element {
  return (
    <ActivityItemContainer>
      <HeaderBlock
        title="Sizing Changed"
        actor={props.item.actor}
        date={props.item.date}
      />
      <Row>
        <div>
          {props.item.oldSizing != null
            ? formatSizing(props.item.oldSizing)
            : "None"}
        </div>
        <div>to</div>
        <div>
          {props.item.newSizing != null
            ? formatSizing(props.item.newSizing)
            : "None"}
        </div>
      </Row>
    </ActivityItemContainer>
  );
});

const CreationDate = React.memo(function CommentActivity(props: {
  createdDate: Date;
  creatorUserId: string;
}): JSX.Element {
  const lookup = useLookupByUserId(props.creatorUserId);

  return (
    <ActivityItemContainer>
      <Row>
        <UserImage userId={props.creatorUserId} width={25} />
        {lookup.data?.name != null ? `${lookup.data.name} created this task on` : "Task created on"} {moment(props.createdDate).format("h:mm a, ddd, MMM Do")}
      </Row>
    </ActivityItemContainer>
  );
});

function Def({ item }: { item: TaskActivityType }): JSX.Element {
  return <div>{JSON.stringify(item)}</div>;
}

function getComponentForActivityType(
  type: TaskActivityType["type"],
): React.FC<{ item: TaskActivityType, task: TaskItem | Omit<EnhancedTaskItem, "activity"> }> {
  switch (type) {
    case "comment":
      return CommentActivity as React.FC<{ item: TaskActivityType }>;
    case "assignment":
      return AssignmentActivity as React.FC<{ item: TaskActivityType }>;
    case "duedatechange":
      return DueDateChangeActivity as React.FC<{ item: TaskActivityType }>;
    case "statuschange":
      return StatusChangeActivity as React.FC<{ item: TaskActivityType, task: TaskItem | Omit<EnhancedTaskItem, "activity"> }>;
    case "titlechange":
      return TitleChangeActivity as React.FC<{ item: TaskActivityType }>;
    case "descriptionchange":
      return DescriptionChangeActivity as React.FC<{ item: TaskActivityType }>;
    case "sizingchange":
      return SizingChangeActivity as React.FC<{ item: TaskActivityType }>;
    case "projectchange":
      return ProjectChangeActivity as React.FC<{ item: TaskActivityType }>;
    default:
      return Def;
  }
}

const TaskActivityItem = React.memo(function TaskActivityItem(props: {
  item: TaskActivityType;
  task: TaskItem | Omit<EnhancedTaskItem, "activity">;
}): JSX.Element {
  const Component = getComponentForActivityType(props.item.type);

  return (
    <TaskActivityItemRow>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
          alignItems: "center",
        }}
      >
        <UserImage userId={props.item.actor} width={25} />
        <div
          style={{ width: 0.5, height: 0, flexGrow: 1, background: "white" }}
        />
      </div>
      <div>
        <div
          style={{
            fontSize: 12,
            height: 25,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {moment(props.item.date).format("h:mm a, ddd MMM Do")}
        </div>
        <Component item={props.item} task={props.task} />
      </div>
    </TaskActivityItemRow>
  );
});

export const ActivityView = React.memo(function ActivityView(props: {
  taskUUID: string;
  task: TaskItem | Omit<EnhancedTaskItem, "activity">;
  createdDate: Date;
  creatorUserId: string;
}): JSX.Element {
  const taskActivityQuery = useGetTaskActivity({ taskUUID: props.taskUUID });
  const addCommentToTaskMutation = useAddCommentToTask({
    querySideEffects: [taskActivityQuery],
  });
  const [commentText, setCommentText] = React.useState<string>("");

  const onClickSendComment = React.useCallback(() => {
    void addCommentToTaskMutation
      .call({
        taskUUID: props.taskUUID,
        text: commentText,
      })
      .then(() => {
        setCommentText("");
      });
  }, [addCommentToTaskMutation, commentText, props.taskUUID]);
  const [commentsOnly, setCommentsOnly] = React.useState(true);

  const activity = React.useMemo(() => {
    return (taskActivityQuery.data?.activity ?? []).filter(item => {
      return item.type === "comment" || !commentsOnly;
    });
  }, [commentsOnly, taskActivityQuery.data?.activity]);

  return (
    <div
      style={{
        maxHeight: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        flexShrink: 0,
      }}
    >
      <Container>
        <Row style={{ alignItems: "center", width: "100%" }}>
          <Input
            multiline
            placeholder="Add a comment..."
            fullWidth
            onChange={(e) => setCommentText(e.target.value)}
            value={commentText}
            disabled={addCommentToTaskMutation.isLoading}
          />
          <CircleButton
            onClick={onClickSendComment}
            size={40}
            isLoading={addCommentToTaskMutation.isLoading}
            square
          />
        </Row>
        <Button
          onClick={() => setCommentsOnly(v => !v)}
          variant={ButtonVariant.narrow}
          label={commentsOnly ? "Show All Activity" : "Show Comments Only"}
          isSecondary
        />
        {taskActivityQuery.isLoading || taskActivityQuery.isError ? (
          <LoadingSpinner />
        ) : (
          activity.map((item) => (
            <TaskActivityItem item={item} key={item.date.valueOf()} task={props.task} />
          ))
        )}
        <CreationDate createdDate={props.createdDate} creatorUserId={props.creatorUserId} />
      </Container>
    </div>
  );
});
