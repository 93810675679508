import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowTurnUp,
  faArrowUpRightFromSquare,
  faCheck,
  faDownload,
  faEmptySet,
  faExclamationTriangle,
  faGrid,
  faLink,
  faList,
  faPencil,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { FindView } from "@hiyllo/ux/find";
import { useDeriveURL, useNavigate } from "@hiyllo/omni-router";
import { Features } from "@hiyllo/omni-common/src/types/navigation/features";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { useAlertDialog, useShowAlert, useShowDialog } from "@hiyllo/ux/dialogs";
import { useRenameElement } from "../hooks/use-rename-element";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { useCreateDocument } from "../hooks/use-create-document";
import { StuffHomeView } from "./stuff-home-view";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import {
  ContextMenuContainer,
  ContextMenuItem,
  useContextMenu,
} from "@hiyllo/ux/context-menu";
import { type EDataType } from "@hiyllo/omni-common/src/types/navigation/edata";
import { openWindow } from "../../../platform/open-window";
import * as MoveElementBP from "../../../blueprints/stuff/move-element";
import * as GenerateImageBP from "../../../blueprints/playground/generate-image";
import { seamlessClient } from "../../../seamless-client";
import { MoopsyError } from "@moopsyjs/core";
import { TabDetails } from "../../tokyo/tabbing/tabs-provider";
import { AssetIcon, ContainerIcon, SortDirection, SortKey, useCreateFolder, useCreateForm, useDeleteElement, useFetchContainer, useUploadFilesToStuffAssets } from "@hiyllo/omni-stuff";
import fuzzysort from "fuzzysort";
import { Modal } from "@hiyllo/ux/modal";
import { NymblIcon } from "@hiyllo/icons";

import { FileAssetView } from "../views/file-asset-view";
import { EnhancedFileAsset } from "../../../types/stuff/asset";
import { UserFacingStuffElement } from "../../../types/stuff/element";
import { ContainerSelectorPanel } from "./container-selector-panel";
import { IS_BETA_ENV } from "../../../platform/xp";
import { FileGallery } from "../../../platform/file-gallery";

const OvularButton = styled("div", ({ $theme }) => ({
  background: $theme.midground,
  fontFamily: "hiyllo",
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 16,
  paddingRight: 16,
  borderRadius: 32,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  userSelect: "none",
  cursor: "pointer",
}));

const Skeleton = styled<"div", { ready: boolean }>(
  "div",
  ({ $theme, ready }) =>
    ready ? {} : { background: $theme.midground, color: "transparent" },
);

const Container = styled("div", {
  fontFamily: '"hiyllo", sans-serif',
  padding: 20,
  height: "calc(100% - 40px)",
  display: "flex",
  flexDirection: "column",
  gap: 15,
});

const Header = styled("div", {
  fontFamily: '"hiyllo", sans-serif',
  fontSize: 32,
  display: "flex",
  flexDirection: "row",
  gap: 10,
});

const ContentsTable = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: $theme.background1,
  gap: 1,
  borderRadius: 10,
  overflowX: "hidden",
  overflowY: "auto",
  height: 10,
  flexGrow: 1,
}));

const Row = styled<"div", { draggedOver?: boolean }>("div", ({ $theme, draggedOver }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  background: draggedOver ? $theme.colorSubtleAccent : $theme.background3,
  paddingLeft: 5,
  paddingRight: 7.5,
  height: 40,
  flexShrink: 0,
  // gap: 1
}));

const TableEndSpacer = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  height: 10,
  flexGrow: 1,
}));

const Column = styled<"div", { size: string; sortable?: boolean }>(
  "div",
  ({ size, sortable }) => ({
    width: size,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    cursor: sortable ? "pointer" : "default",
    userSelect: sortable ? "none" : "text",
  }),
);

const HeaderRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

const ContextMenuText = styled("div", ({ $theme }) => ({
  background: $theme.midground,
  fontFamily: "hiyllo",
  padding: 5,
  paddingLeft: 10,
  paddingRight: 10,
  fontSize: 10,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
}));

const ElementRow = React.memo(function AssetRow(props: {
  element: UserFacingStuffElement;
  containerQuery: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const content = props.element;
  const deleteElementController = useDeleteElement({});
  const renameElementMutation = useRenameElement();
  const cxMenu = useContextMenu();

  const onContextMenu = React.useCallback((evt: React.MouseEvent) => {
    evt.preventDefault();
    cxMenu.open(
      evt as unknown as React.SyntheticEvent<HTMLButtonElement, MouseEvent>,
    );
  }, []);

  const showImagePreview = content.type === "asset" && content.asset.type === "file" && content.asset.thumbnailSrc != null;

  const download = React.useCallback(() => {
    if (content.type === "asset" && content.asset.type === "file") {
      openWindow(content.asset.src);
    }
  }, [content]);

  const navigate = useNavigate();
  const showDialog = useShowDialog();
  const showAlert = useShowAlert();

  const deleteElement = React.useCallback(() => {
    void deleteElementController.deleteElement(content);
  }, [content, deleteElementController]);

  const eDataPath: EDataType = React.useMemo(() => {
    if (content.type === "container") {
      return {
        feature: Features.stuff,
        params: {
          view: "container",
          uuid: content.container.uuid,
        },
      };
    } else if (content.asset.type === "form") {
      return {
        feature: Features.forms,
        params: {
          view: "edit",
          formUUID: content.asset.uuid,
        },
      };
    } else if (content.asset.type === "meeting-recording") {
      return {
        feature: Features.stuff,
        params: {
          view: "meeting-recording",
          uuid: content.asset.uuid,
        },
      };
    } else if (content.asset.type === "document") {
      return {
        feature: Features.stuff,
        params: {
          view: "document",
          uuid: content.asset.uuid,
        },
      };
    } else {
      return {
        feature: Features.stuff,
        params: {
          view: "asset",
          uuid: content.asset.uuid,
        },
      };
    }
  }, [content]);

  const open = React.useCallback(() => {
    navigate(eDataPath);
  }, [eDataPath, navigate]);
  const deriveURL = useDeriveURL();

  const openInNewWindow = React.useCallback(() => {
    openWindow(deriveURL(eDataPath));
    cxMenu.close();
  }, [cxMenu, deriveURL, eDataPath]);

  const renameElement = React.useCallback(() => {
    showDialog({
      title: `Rename ${content.type === "asset" ? content.asset.name : content.container.name
        }`,
      message: `Select a new name...`,
      defaultValue:
        content.type === "asset" ? content.asset.name : content.container.name,
      requireValue: true,
      onSubmit: (name: string) => {
        void renameElementMutation.call({
          uuid:
            content.type === "asset"
              ? content.asset.uuid
              : content.container.uuid,
          type: content.type,
          name,
        });
      },
    });
  }, [content, renameElementMutation, showDialog]);
  const moveElementMutation = seamlessClient.useMutation<MoveElementBP.Plug>(MoveElementBP);

  const [draggedOver, setDraggedOver] = React.useState(false);
  const [beingDragged, setBeingDragged] = React.useState(false);
  const key = content.type === "container"
    ? content.container.uuid
    : content.asset.uuid;
  const dragEnterRef = React.useRef(0);

  const [showMove, setShowMove] = React.useState(false);
  const moveElement = React.useCallback(() => {
    setShowMove(true);
  }, []);

  const moveTo = React.useCallback((containerUUID: string) => {
    setShowMove(false);
    const elementUUID = content.type === "asset" ? content.asset.uuid : content.container.uuid;

    if (containerUUID === elementUUID) {
      return;
    }

    moveElementMutation.call({
      kind: content.type,
      elementUUID,
      newParent: containerUUID
    }).catch(err => {
      void showAlert({
        title: "Unable to move",
        message: (err as MoopsyError).message
      });
    });
  }, [content, moveElementMutation, showAlert]);

  const onDrop = React.useCallback((evt: React.DragEvent) => {
    setDraggedOver(false);

    const data = evt.dataTransfer.getData("hiyllo/stuff-element");

    if (data === "" || content.type !== "container") {
      console.warn(data, content.type);
      return;
    }

    const [kind, elementUUID] = data.split("/");

    if (content.container.uuid === elementUUID) {
      return;
    }

    moveElementMutation.call({
      kind: (kind as "container" | "asset"),
      elementUUID,
      newParent: content.container.uuid
    }).catch(err => {
      void showAlert({
        title: "Unable to move",
        message: (err as MoopsyError).message
      });
    });
  }, [content, moveElementMutation, showAlert]);

  const [linkCopied, setLinkCopied] = React.useState(false);
  const copyLink = React.useCallback(() => {
    void window.navigator.clipboard.writeText(deriveURL(eDataPath)).then(() => {
      setLinkCopied(true);
    });
  }, [deriveURL, eDataPath]);

  return (
    <Row
      key={key}
      onContextMenu={onContextMenu}
      draggable={
        content.type === "asset" || (content.type === "container" && content.container.type === "arbitrary-folder")
      }
      onDragStart={(evt: React.DragEvent) => {
        evt.dataTransfer.clearData();
        evt.dataTransfer.setData("hiyllo/stuff-element", `${content.type}/${key}`);
        setBeingDragged(true);
      }}
      onDragEnd={() => {
        setBeingDragged(false);
      }}
      onDragEnter={(evt: React.DragEvent) => {
        dragEnterRef.current++;

        if (evt.dataTransfer.types.includes("hiyllo/stuff-element") && content.type === "container") {
          setDraggedOver(true);
        }
      }}
      onDrop={onDrop}
      onDragLeave={() => {
        dragEnterRef.current--;
        if (dragEnterRef.current <= 0) {
          setDraggedOver(false);
        }
      }}
      draggedOver={!beingDragged && draggedOver}
      className="stuff-containerview-row"
    >
      {showMove ?
        <Modal onClose={() => setShowMove(false)}>
          <ContainerSelectorPanel label="Move to" onSelect={moveTo} />
        </Modal>
        : null}
      <cxMenu.CXMenuContainer>
        <ContextMenuContainer>
          <ContextMenuText>
            {content.type === "container"
              ? <ContainerIcon type={content.container.type} color="white" />
              : <AssetIcon element={content.asset} color="white" size={12.5} fixedWidth />}
            {content.type === "asset"
              ? content.asset.name
              : content.container.name}
          </ContextMenuText>
          <ContextMenuItem
            icon={faArrowUpRightFromSquare}
            label="Open in New Tab"
            onClick={openInNewWindow}
          />
        </ContextMenuContainer>
      </cxMenu.CXMenuContainer>
      <Column
        size="40%"
        onClick={open}
        style={{ cursor: "pointer", paddingLeft: 15 }}
      >
        {showImagePreview ? (
          <img
            src={
              (content.asset as EnhancedFileAsset).thumbnailSrc ?? ""
            }
            style={{
              height: 30,
              width: 30,
              objectFit: "contain",
            }}
          />
        ) : content.type === "container" ? (
          <ContainerIcon type={content.container.type} color="white" />
        ) : (
          <AssetIcon element={content.asset} color="white" size={17.5} fixedWidth />
        )}
        <div className="stuff-container-row-name">
          {content.type === "asset" ? content.asset.name : content.container.name}
        </div>
      </Column>
      <Column size="40%">
        {moment(
          content.type === "asset"
            ? content.asset.lastEdited
            : content.container.lastEdited,
        ).format("h:mm a, dddd MMM Do")}
      </Column>
      <Column size="20%">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <CircleButton icon={faTrash} size={26} onClick={deleteElement} secondary />
          <CircleButton icon={faPencil} size={26} onClick={renameElement} secondary />
          <CircleButton icon={linkCopied ? faCheck : faLink} size={26} onClick={copyLink} secondary />
          {content.type === "asset" || (content.container.type !== "user-root" && content.container.type !== "organization-root" && content.container.type !== "team-root" && content.container.type !== "project-root") ?
            <CircleButton icon={faArrowRight} size={26} onClick={moveElement} secondary />
            : null}
          {content.type === "asset" && content.asset.type === "file" ? (
            <CircleButton icon={faDownload} size={26} onClick={download} secondary />
          ) : null}
        </div>
      </Column>
    </Row>
  );
});

export enum DisplayMode {
  grid = "grid",
  list = "list"
}

const ElementTile = React.memo(function AssetRow(props: {
  element: UserFacingStuffElement;
  containerQuery: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const content = props.element;
  const deleteElementController = useDeleteElement({});
  const renameElementMutation = useRenameElement();
  const cxMenu = useContextMenu();

  const onContextMenu = React.useCallback((evt: React.MouseEvent) => {
    evt.preventDefault();
    cxMenu.open(
      evt as unknown as React.SyntheticEvent<HTMLButtonElement, MouseEvent>,
    );
  }, []);

  const download = React.useCallback(() => {
    if (content.type === "asset" && content.asset.type === "file") {
      openWindow(content.asset.src);
    }
  }, [content]);

  const navigate = useNavigate();
  const showDialog = useShowDialog();

  const deleteElement = React.useCallback((evt?: React.SyntheticEvent) => {
    evt?.stopPropagation();
    void deleteElementController.deleteElement(content);
  }, [content, deleteElementController]);

  const eDataPath: EDataType = React.useMemo(() => {
    if (content.type === "container") {
      return {
        feature: Features.stuff,
        params: {
          view: "container",
          uuid: content.container.uuid,
        },
      };
    } else if (content.asset.type === "form") {
      return {
        feature: Features.forms,
        params: {
          view: "edit",
          formUUID: content.asset.uuid,
        },
      };
    } else if (content.asset.type === "meeting-recording") {
      return {
        feature: Features.stuff,
        params: {
          view: "meeting-recording",
          uuid: content.asset.uuid,
        },
      };
    } else if (content.asset.type === "document") {
      return {
        feature: Features.stuff,
        params: {
          view: "document",
          uuid: content.asset.uuid,
        },
      };
    } else {
      return {
        feature: Features.stuff,
        params: {
          view: "asset",
          uuid: content.asset.uuid,
        },
      };
    }
  }, [content]);

  const [displayAsset, setDisplayAsset] = React.useState(false);
  const open = React.useCallback(() => {
    if (content.type === "asset" && content.asset.type === "file") {
      setDisplayAsset(true);
    }
    else {
      navigate(eDataPath);
    }
  }, [content, eDataPath, navigate]);
  const deriveURL = useDeriveURL();

  const openInNewWindow = React.useCallback(() => {
    openWindow(deriveURL(eDataPath));
    cxMenu.close();
  }, [cxMenu, deriveURL, eDataPath]);

  const renameElement = React.useCallback(() => {
    showDialog({
      title: `Rename ${content.type === "asset" ? content.asset.name : content.container.name
        }`,
      message: `Select a new name...`,
      defaultValue:
        content.type === "asset" ? content.asset.name : content.container.name,
      requireValue: true,
      onSubmit: (name: string) => {
        void renameElementMutation.call({
          uuid:
            content.type === "asset"
              ? content.asset.uuid
              : content.container.uuid,
          type: content.type,
          name,
        });
      },
    });
  }, [content, renameElementMutation, showDialog]);

  return (
    <>
      {displayAsset && content.type === "asset" ?
        <Modal onClose={() => setDisplayAsset(false)}>
          <FileAssetView asset={content.asset as EnhancedFileAsset} inModal />
        </Modal>
        : null}
      <div onContextMenu={onContextMenu} onClick={open} style={{ borderRadius: 10, width: "calc(25% - 10px)", height: 250, background: "rgba(255, 255, 255, 0.1)", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 10 }}>
        <cxMenu.CXMenuContainer>
          <ContextMenuContainer>
            <ContextMenuText>
              {content.type === "container"
                ? <ContainerIcon type={content.container.type} color="white" />
                : <AssetIcon element={content.asset} color="white" />}
              {content.type === "asset"
                ? content.asset.name
                : content.container.name}
            </ContextMenuText>
            <ContextMenuItem
              icon={faArrowUpRightFromSquare}
              label="Open in New Tab"
              onClick={openInNewWindow}
            />
          </ContextMenuContainer>
        </cxMenu.CXMenuContainer>
        <div style={{ fontSize: 140 }}>
          {content.type === "asset" && content.asset.type === "file" && content.asset.thumbnailSrc != null ?
            <img src={content.asset.thumbnailSrc} style={{ maxHeight: 140, maxWidth: "100%", objectFit: "contain" }} />
            : content.type === "container" ? (
              <ContainerIcon type={content.container.type} color="white" size={140} />
            ) : (
              <AssetIcon element={content.asset} color="white" size={140} />
            )}
        </div>
        <div style={{ maxWidth: "80%", textAlign: "center" }}>
          {content.type === "asset" ? content.asset.name : content.container.name}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <CircleButton icon={faTrash} size={26} onClick={deleteElement} />
          <CircleButton icon={faPencil} size={26} onClick={renameElement} />
          {content.type === "asset" && content.asset.type === "file" ? (
            <CircleButton icon={faDownload} size={26} onClick={download} />
          ) : null}
        </div>
      </div>
    </>
  );
});

export const StuffContainerView = React.memo(
  function StuffContainerView(props: { uuid: string }): JSX.Element {
    const navigate = useNavigate();
    const { containerQuery, sortedElements, container, sortOptions, setSortOptions } = useFetchContainer({ uuid: props.uuid as string });
    const { uploadFilesToStuffAssets, pendingUploads } = useUploadFilesToStuffAssets({
      parentUUID: props.uuid as string
    });

    const onSelectSortKey = React.useCallback(
      (key: SortKey) => {
        if (sortOptions.key === key) {
          setSortOptions({
            key,
            direction:
              sortOptions.direction === SortDirection.ascending
                ? SortDirection.descending
                : SortDirection.ascending,
          });
        } else {
          setSortOptions({
            key,
            direction: SortDirection.descending,
          });
        }
      },
      [setSortOptions, sortOptions.direction, sortOptions.key],
    );

    const [displayMode, setDisplayMode] = React.useState<DisplayMode>(window.localStorage.stuffDisplayMode ?? DisplayMode.list);
    const onSwitchDisplayMode = React.useCallback(() => {
      const newMode = displayMode === DisplayMode.grid ? DisplayMode.list : DisplayMode.grid;
      setDisplayMode(newMode);
      window.localStorage.stuffDisplayMode = newMode;
    }, [displayMode]);
    const createDocumentMutation = useCreateDocument();
    const fileUploadInput = React.useRef<HTMLInputElement>(null);
    const showDialog = useShowDialog();

    const onCreateDocument = React.useCallback(() => {
      showDialog({
        title: "Create Document",
        message: "What would you like to name your document?",
        requireValue: true,
        onSubmit: (value) => {
          void createDocumentMutation
            .call({
              name: value,
              parentUUID: props.uuid,
            })
            .then((res) => {
              navigate({
                feature: Features.stuff,
                params: {
                  view: "document",
                  uuid: res.uuid,
                },
              });
            });
        },
      });
    }, [createDocumentMutation, navigate, props.uuid, showDialog]);

    const onDrop = React.useCallback(
      (evt: React.DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        evt.stopPropagation();
        console.log(uploadFilesToStuffAssets, props.uuid);
        void uploadFilesToStuffAssets([...evt.dataTransfer.files]);
      },
      [props.uuid, uploadFilesToStuffAssets],
    );

    const onUploadFile = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
      if (fileUploadInput.current?.files != null) {
        void uploadFilesToStuffAssets([...(evt.target.files ?? [])]);
      }
    }, [uploadFilesToStuffAssets]);

    const { createFolder } = useCreateFolder({ parentUUID: props.uuid });
    const { onCreateForm } = useCreateForm({ parentUUID: props.uuid });

    const tabIcon = React.useMemo(() => <ContainerIcon type={container?.type ?? "arbitrary-folder"} color="white" />, [container?.type]);
    const generateImageMutation = seamlessClient.useMutation<GenerateImageBP.Plug>(GenerateImageBP, { timeout: 30000 });
    const [generateImageSrc, setGenerateImageSrc] = React.useState<string | null>(null);

    const generateImage = React.useCallback(() => {
      void showDialog({
        title: "Enter a prompt",
        message: "What would you like to generate?",
        requireValue: true,
        onSubmit: async (prompt) => {
          const { src } = await generateImageMutation.call({
            prompt,
            guidanceScale: 13,
            steps: 50
          });

          setGenerateImageSrc(src);
        }
      });
    }, [generateImageMutation, showDialog]);

    const [search, setSearch] = React.useState("");
    const filteredElements = React.useMemo(() => {
      if (search.trim() === "") return sortedElements;

      console.log(719, sortedElements);

      const data = fuzzysort.go(search, sortedElements, {
        keys: ["name"],
        threshold: -10000
      });

      return data.map((result) => result.obj);
    }, [search, sortedElements]);

    return (
      <TabDetails icon={tabIcon} label={container?.name ?? "Stuff"}>
        <Container
          onDrop={onDrop}
          onDragOver={(evt: any) => {
            evt.preventDefault();
          }}
        >
          <FindView
            value={search}
            onChangeValue={setSearch}
            placeholder="Find in folder..."
          />
          {generateImageSrc != null ?
            <Modal onClose={() => setGenerateImageSrc(null)}>
              <FileGallery files={[{ src: generateImageSrc }]} inModal />
            </Modal>
            : null}
          {props.uuid == null ? (
            <StuffHomeView />
          ) : (
            <HeaderRow>
              <Header>
                {container?.parent != null ? (
                  <div
                    style={{
                      height: "1em",
                      width: "1em",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate({
                        feature: Features.stuff,
                        params: {
                          view: "container",
                          uuid: container.parent,
                        },
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowTurnUp}
                      flip="horizontal"
                      style={{ fontSize: 18 }}
                      fixedWidth
                    />
                  </div>
                ) : null}
                <ContainerIcon type={container?.type ?? "arbitrary-folder"} color="white" />{" "}
                <Skeleton ready={props.uuid == null || container != null}>
                  {props.uuid == null ? "Home" : container?.name ?? "Loading..."}
                </Skeleton>
              </Header>
              {props.uuid != null ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <CircleButton size={30} secondary onClick={onSwitchDisplayMode} icon={displayMode === DisplayMode.grid ? faList : faGrid} />
                  {IS_BETA_ENV && container?.type === 'user-root' ?
                    <OvularButton onClick={generateImage}>
                      <NymblIcon width={17.5} color="white" />
                    </OvularButton>
                    : null}
                  {pendingUploads.length > 0 ? (
                    <>
                      <LoadingSpinner />
                      <div>{pendingUploads.length} file{pendingUploads.length !== 1 ? "s" : ""} left to upload</div>
                    </>
                  ) : (
                    <label>
                      <input
                        type="file"
                        onChange={onUploadFile}
                        hidden
                        ref={fileUploadInput}
                        multiple
                      />
                      <OvularButton>Upload File</OvularButton>
                    </label>
                  )}
                  <OvularButton onClick={createFolder}>
                    Create Folder
                  </OvularButton>
                  <OvularButton onClick={onCreateForm}>Create Form</OvularButton>
                  <OvularButton onClick={onCreateDocument}>
                    Create Document
                  </OvularButton>
                </div>
              ) : null}
            </HeaderRow>
          )}
          {containerQuery.isError ?
            <div style={{ height: 0, flexGrow: 1 }}>
              <EmptySplash
                icon={faExclamationTriangle}
                label="Failed to load this folder"
                hint={containerQuery.error.message}
              />
            </div>
            : containerQuery.data != null &&
              containerQuery.data.elements.length === 0 ? (
              <div style={{ height: 0, flexGrow: 1 }}>
                {containerQuery.data.container?.type === "user-root" ?
                  <EmptySplash
                    icon={faEmptySet}
                    label="Nothing Here Yet"
                    hint="This is your personal space. Things in here are private unless you choose to share them. Add something with the buttons at the top-right, or drop a file here to upload"
                  />
                  :
                  <EmptySplash
                    icon={faEmptySet}
                    label="Nothing Here Yet"
                    hint="Add something with the buttons at the top-right, or drop a file here to upload"
                  />
                }
              </div>
            ) : (
              displayMode === DisplayMode.grid ?
                <div style={{ display: "flex", flexDirection: "row", alignItems: "stretch", flexWrap: "wrap", gap: 10 }}>
                  {filteredElements.map((content) => (
                    <ElementTile
                      key={
                        content.type === "asset"
                          ? content.asset.uuid
                          : content.container.uuid
                      }
                      element={content}
                      containerQuery={containerQuery}
                    />
                  ))}
                </div>
                :
                <ContentsTable>
                  <Row className="stuff-containerview-row">
                    <Column
                      size="40%"
                      onClick={() => onSelectSortKey(SortKey.name)}
                      sortable
                      style={{
                        paddingLeft: 15,
                      }}
                    >
                      Name
                      {sortOptions.key === SortKey.name ? (
                        <FontAwesomeIcon
                          icon={
                            sortOptions.direction === SortDirection.ascending
                              ? faCaretUp
                              : faCaretDown
                          }
                        />
                      ) : null}
                    </Column>
                    <Column
                      size="40%"
                      onClick={() => onSelectSortKey(SortKey.lastEdited)}
                      sortable
                    >
                      Last Edited
                      {sortOptions.key === SortKey.lastEdited ? (
                        <FontAwesomeIcon
                          icon={
                            sortOptions.direction === SortDirection.ascending
                              ? faCaretUp
                              : faCaretDown
                          }
                        />
                      ) : null}
                    </Column>
                    <Column size="20%">Actions</Column>
                  </Row>
                  {filteredElements.map((content) => (
                    <ElementRow
                      key={
                        content.type === "asset"
                          ? content.asset.uuid
                          : content.container.uuid
                      }
                      element={content}
                      containerQuery={containerQuery}
                    />
                  ))}
                  <TableEndSpacer />
                </ContentsTable>
            )}
        </Container>
      </TabDetails>
    );
  },
);
