import { UseMoopsyQueryRetValAny } from "@moopsyjs/react/main";
import React from "react";
import { AlertSpecialType, AlertType } from "../../../../types/alerts/alert";
import { AlertMessageCard } from "./alert-message-card";
import { AlertEventCard } from "./alert-event-card";
import { AlertCallCard } from "./alert-call-card";
import { BasicAlertCard } from "./alert-item";

export const AlertRenderer = React.memo(function AlertRenderer(props: {
  isPopup?: boolean;
  onClose: () => void;
  alertsCountQuery?: UseMoopsyQueryRetValAny;
  alertsQuery?: UseMoopsyQueryRetValAny;
  alert: AlertType;
}): JSX.Element {
  const displayAlert = props.alert;

  return (
    displayAlert.ead?.type === "chat/message" ?
      <AlertMessageCard ead={displayAlert.ead} onClose={props.onClose} />
      : displayAlert.ead?.type === "meeting" ? (
        <AlertEventCard ead={displayAlert.ead} onClose={props.onClose} />
      ) : displayAlert.specialType === AlertSpecialType.call ? (
        <AlertCallCard
          alert={displayAlert}
          onClose={props.onClose}
        />
      ) : (
        <BasicAlertCard
          isPopup={true}
          key={displayAlert.uuid}
          alert={displayAlert}
          alertsCountQuery={props.alertsCountQuery}
          onClose={props.onClose}
        />
      )
  );
});