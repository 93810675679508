/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export enum Features {
  posts = "posts",
  chat = "chat",
  profile = "profile",
  tasks = "tasks",
  calendar = "calendar",
  tickets = "tickets",
  swipeMeet = "swipeMeet",
  greenlisting = "greenlisting",
  mail = "mail",
  meet = "meet",
  home = "home",
  organization = "organization",
  stuff = "stuff",
  support = "support",
  workspaceConfig = "workspaceConfig",
  forms = "forms",
  feedback = "feedback",
  studio = "studio",
  wardrobe = "wardrobe",
  contacts = "contacts",
  talent = "talent"
}
