import { useMaybeRoomContext } from "@livekit/components-react";
import { ScreenShareCaptureOptions, TrackPublishOptions } from "livekit-client";
import React from "react";

export function useStartScreenShare() {
  const room = useMaybeRoomContext();
  const roomRef = React.useRef(room);
  roomRef.current = room;

  return React.useCallback(async (
    captureOptions: ScreenShareCaptureOptions,
    publishOptions: TrackPublishOptions
  ) => {
    if (!roomRef.current) {
      return;
    }

    const { localParticipant } = roomRef.current;

    await localParticipant.setScreenShareEnabled(
      true,
      captureOptions,
      publishOptions
    );
  }, []);
}