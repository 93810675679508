import React from "react";
import { seamlessClient } from "../seamless-client";
import { UpdateBanner } from "../platform/components/update-banner";
import { Electron } from "../platform/electron";

// @ts-expect-error window._omniCommitHash
const embeddedCommitHash: string | null = window._omniCommitHash ?? null;

export const UpdateProvider = React.memo(
  function UpdateProvider(): null | JSX.Element {
    const [hasUpdate, setHasUpdate] = React.useState<string | null>(null);

    React.useEffect(() => {
      if (!Electron.isElectron) {
        console.log("[UpdateProvider] Listening for web updates");
        const cb = (): void => {
          void fetch("/_cmthsh").then(async (res) => {
            const newVersion = await res.text();
            if (newVersion !== embeddedCommitHash) {
              setHasUpdate(`New version available: ${newVersion} (current: ${embeddedCommitHash})`);
            }
          });
        };

        seamlessClient.on.transportStatusChange(cb);

        return () => {
          seamlessClient.off.transportStatusChange(cb);
        };
      }
      else {
        console.log("[UpdateProvider] Listening for electron updates");
        Electron.on("update-available", () => {
          console.log("[UpdateProvider] Update is available!");
          setHasUpdate("New version available (Electron)");
        });
      }
    }, []);

    if (hasUpdate != null) {
      return <UpdateBanner reason={hasUpdate} />;
    }

    return null;
  },
);
