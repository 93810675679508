import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { UserImage } from "@hiyllo/omni-images";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { useLookupByUserId } from "@hiyllo/omni-continuity";
import { useCustomDataForProjects } from "@hiyllo/omni-tasks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContextMenuContainer, ContextMenuItem, useContextMenu } from "@hiyllo/ux/context-menu";
import { faEllipsisV, faPerson, faRunning, faSquare, faSquareCheck, faUserCircle, faUserSlash } from "@fortawesome/pro-light-svg-icons";

import { useWorkerResult } from "../../../platform/worker";
import { FilterControl } from "../components/filter-control";
import { TaskKanbanView } from "../kanban-view/task-kanban-view";
import { type ListTasksSlimTaskType } from "../../../types/tasks/task-item";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { TaskPriorityV3, TaskStatusV3, type TaskOrderType } from "../../../types/tasks/tasks-organization";

export type ProjectData = {
  statuses: TaskStatusV3[] | null;
  priorities: TaskPriorityV3[] | null;
};

const Container = styled("div", {
  height: "100%",
  padding: 0,
  display: "flex",
  flexDirection: "column",
  gap: 10,
});

const ViewContainer = styled('div', {
  height: 0,
  flexGrow: 1,
});

const SpaceBetweenRow = styled("div", ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

export interface TasksViewPropsType {
  order: TaskOrderType;
  onChangeOrder: (
    order: TaskOrderType,
    updatedTask: ListTasksSlimTaskType | null,
  ) => void;
  tasks: ListTasksSlimTaskType[];
  isReady?: boolean;
  columns?: string[] | null;
  hideHeader?: boolean;
  onClickTask?: (task: ListTasksSlimTaskType) => boolean;
  hideTaskProjectLabels?: boolean;
  projectUUID?: string | null;
  cacheKey: string;
}

const AssigneeOption = React.memo(function AssigneeOption(props: {
  userId: string;
}): JSX.Element {
  const lookup = useLookupByUserId(props.userId);
  return (
    <>
      <UserImage userId={props.userId} width={15} />
      {lookup.data?.name}
    </>
  );
});

type SprintFilterType = "with-sprint-only" | "without-sprint-only" | null;
type AssigneeFilterType = "assigned" | "unassigned" | string | null;

export const TasksView = React.memo(function TasksView(
  props: TasksViewPropsType,
): JSX.Element {
  React.useEffect(() => {
    console.debug("<TasksView> First Render");
  }, []);

  const [showWontDo, setShowWontDo] = React.useState<boolean>(false);
  const cxMenu = useContextMenu();

  const ViewComponent = TaskKanbanView;

  const projects = useWorkerResult<ListTasksSlimTaskType[], string[]>(new URL("./workers/reduce-to-project-uuids", import.meta.url), props.tasks);
  const rawData = useCustomDataForProjects(projects ?? []);
  const rawColumns = useWorkerResult<ProjectData[] | null, TaskStatusV3[]>(new URL("./workers/compute-columns", import.meta.url), rawData);
  const assigneeData = useWorkerResult<ListTasksSlimTaskType[], { assignees: string[], hasNull: boolean }>(new URL("./workers/reduce-to-assignees", import.meta.url), props.tasks);
  const columns = React.useMemo(() => {
    return showWontDo ? rawColumns : rawColumns?.filter((column) => column.hideFromKanban !== true);
  }, [rawColumns, showWontDo]);


  const [sprintFilter, setSprintFilter] = React.useState<SprintFilterType>((window.localStorage.getItem(`@tasks/${props.cacheKey}/sprint-filter`) as SprintFilterType | null) || null);
  const [assigneeFilter, setAssigneeFilter] = React.useState<AssigneeFilterType>((window.localStorage.getItem(`@tasks/${props.cacheKey}/assignee-filter`)) || null);

  React.useEffect(() => {
    window.localStorage.setItem(`@tasks/${props.cacheKey}/sprint-filter`, sprintFilter ?? "");
    window.localStorage.setItem(`@tasks/${props.cacheKey}/assignee-filter`, assigneeFilter ?? "");
  }, [assigneeFilter, props.cacheKey, sprintFilter]);

  const filteredTasks = React.useMemo(() => {
    return props.tasks.filter((t) => {
      if (sprintFilter === "with-sprint-only" && t.sprintUUID == null) {
        return false;
      }
      if (sprintFilter === "without-sprint-only" && t.sprintUUID != null) {
        return false;
      }

      if (assigneeFilter != null) {
        if (assigneeFilter === "assigned" && t.assigneeUserId == null) {
          return false;
        }
        else if (assigneeFilter === "unassigned" && t.assigneeUserId != null) {
          return false;
        }
        else if (t.assigneeUserId !== assigneeFilter) {
          return false;
        }
      }

      return true;
    });
  }, [assigneeFilter, props.tasks, sprintFilter]);

  if (columns == null || assigneeData == null) {
    return <LoadingSpinnerFullView />;
  }

  const { assignees, hasNull: hasUnassigned } = assigneeData;

  return (
    <Container>
      <cxMenu.CXMenuContainer>
        <ContextMenuContainer>
          <ContextMenuItem
            icon={showWontDo ? faSquareCheck : faSquare}
            label="Show Hidden Columns"
            onClick={() => {
              setShowWontDo(!showWontDo);
            }}
          />
        </ContextMenuContainer>
      </cxMenu.CXMenuContainer>
      <SpaceBetweenRow>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
          <FilterControl<SprintFilterType | null>
            key="sprint-filter"
            value={sprintFilter}
            onChangeValue={setSprintFilter}
            renderOption={(option) => (
              option === "with-sprint-only" ?
                <>
                  <FontAwesomeIcon icon={faRunning} fixedWidth />
                  Tasks with Sprints
                </>
                :
                <>
                  <FontAwesomeIcon icon={faPerson} fixedWidth />
                  Task without Sprints
                </>
            )}
            options={[
              "without-sprint-only",
              "with-sprint-only",
            ]}
            noValueElement={
              <>
                <FontAwesomeIcon icon={faRunning} />
                Filter by Sprint
              </>
            }
          />
          {hasUnassigned || assignees.length > 1 ? <FilterControl<AssigneeFilterType | null>
            key="sprint-filter"
            value={assigneeFilter}
            onChangeValue={setAssigneeFilter}
            renderOption={(option) => (
              option === "assigned" ?
                <>
                  <FontAwesomeIcon icon={faUserCircle} fixedWidth />
                  Assigned
                </>
                : option === "unassigned" ?
                  <>
                    <FontAwesomeIcon icon={faUserSlash} fixedWidth />
                    Unassigned
                  </>
                  :
                  <AssigneeOption userId={option} />
            )}
            options={[
              "unassigned",
              "assigned",
              ...(assignees ?? [])
            ]}
            noValueElement={
              <>
                <FontAwesomeIcon icon={faUserCircle} />
                Filter by Assignee
              </>
            }
          /> : null}
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
          <CircleButton
            icon={faEllipsisV}
            onClick={(evt) => {
              if (evt != null) cxMenu.open(evt);
            }}
            size={27.5}
            secondary
          />
        </div>
      </SpaceBetweenRow>
      <ViewContainer>
        <ViewComponent
          order={props.order}
          onChangeOrder={props.onChangeOrder}
          tasks={filteredTasks}
          isReady={props.isReady}
          columns={columns}
          hideTaskProjectLabels={props.hideTaskProjectLabels}
          projectUUID={props.projectUUID}
        />
      </ViewContainer>
    </Container>
  );
});
