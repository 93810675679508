import React from "react";
import { type ListTasksSlimTaskType } from "../../../types/tasks/task-item";
import { TinyPillContainer } from "../components/tiny-pill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePriorityDisplay } from "@hiyllo/omni-tasks/main";

export const CondensedDetailsPill = React.memo(
  function CondensedDetailsPill(props: {
    task: Omit<ListTasksSlimTaskType, "assignee">;
  }): JSX.Element {
    const priorityDisplay = usePriorityDisplay(props.task.priority);

    return (
      <TinyPillContainer style={{ paddingLeft: 0, borderRadius: 5 }}>
        <div
          style={{
            height: 25,
            backgroundColor: priorityDisplay.color,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: 5,
            paddingLeft: 5,
            fontSize: 12.5,
          }}
        >
          <FontAwesomeIcon
            icon={priorityDisplay.icon}
            fixedWidth
          />
        </div>
        <div style={{ paddingRight: 5 }}>{props.task.shortId}</div>
      </TinyPillContainer>
    );
  },
);
