import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  HashRouter,
  useParams,
} from "react-router-dom";
import { styled } from "@hiyllo/ux/styled";

import { useConfig } from "../platform/config/config-context";
import { LoginRouter } from "../features/accounts/pages/login";
import { TokyoRoot } from "../features/tokyo";
import { getRootURL, isDesktopApp } from "../platform/environment/get-root-url";
import { DownloadApp } from "../features/static/download-mobile-app";
import { TermsOfUse } from "../features/static/terms-of-use";
import { PrivacyPolicy } from "../features/static/privacy-policy";
import { SSO } from "../features/accounts/sso";
import { BookATimeScheduleView } from "../features/calendar/book-a-time/schedule";
import { RSVPExternal } from "../features/calendar/views/rsvp-external";
import { useSelfMaybe } from "@hiyllo/omni-continuity";
import { CompleteResetPassword } from "../features/accounts/pages/complete-reset-password";
import { SubmitForm } from "../features/forms/views/submit-form";
import { NativeLogin } from "../features/accounts/pages/native-login";
import { CompleteMicrosoftIntegration } from "../features/accounts/views/complete-integration/microsoft";
import { Typography } from "@hiyllo/ux/typography";
import { useIsSolo } from "../platform/hooks/use-is-solo";
import { OAuthAuthorize } from "../features/oauth/authorize";
import { HiylloWorkPreLink } from "../features/oauth/link-pre-page/hiyllo-work-pre-link";
import { HiylloWorkOAuthConfirmation } from "../features/integrations/hiyllo-work/hiyllo-work-oauth-confirmation";
import { CompleteGoogleIntegration } from "../features/accounts/views/complete-integration/google";
import { WardrobeTest } from "../features/wardrobe/test";
import { IntegrationsView } from "../features/accounts/views/integrations";
import { SoloLanding } from "../features/landing/solo";
import { ElectronLogin } from "../features/electron/electron-login";
import { UserGodView } from "../features/admin/user-god-view";
import { MapTest } from "../features/maps/map-test";
import { Electron } from "../platform/electron";
import { SpectatorExternal } from "../features/meet/views/spectator-external";
import { IS_DEV } from "../platform/xp";
import { RouterProviderV1 } from "../features/tokyo/navigation/routers/router-v1";

const AdminPanel = React.lazy(
  async () => await import("../features/admin/dashboard"),
);
const MeetingExternal = React.lazy(
  async () => await import("../features/meet/views/meeting-external"),
);
const AdminSetup = React.lazy(
  async () => await import("../features/admin/view/setup"),
);

const RouterComponent = Electron.isElectron ? HashRouter : BrowserRouter;

const PageContainer = styled("div", {
  width: "100%",
  height: "100%",
  overflow: "auto",
});

const Error404 = React.memo(function Error404(): JSX.Element {
  React.useEffect(() => {
    console.error(
      "404 " + window.location.href,
      { pathname: window.location.pathname },
      new Error("404"),
    );
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        background: "black",
        color: "white",
        textAlign: "center",
      }}
    >
      <div style={{ padding: 20 }}>
        <div style={{ fontSize: 36 }}>404</div>
        <div>
          The link you&apos;ve followed is incorrect or broken. Try again?
        </div>
        <div
          style={{
            marginTop: 10,
            fontFamily: "monospace",
            whiteSpace: "pre-wrap",
            wordBreak: "break-all",
          }}
        >
          {window.location.href}
        </div>
      </div>
    </div>
  );
});

const AboutContainer = styled("div", ({ $theme }) => ({
  background: $theme.background1,
  padding: 20,
  height: "100%",
}));

const Padded = styled("div", {
  padding: 20,
});

const About = React.memo(function About(): JSX.Element {
  const isSolo = useIsSolo();
  return (
    <AboutContainer>
      <Typography.Header>Hiyllo {isSolo ? "Solo" : "Work"}</Typography.Header>
      <Typography.Paragraph>
        &copy; Hiyllo Inc. 2024. All rights reserved.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Hiyllo is a registered trademark of Hiyllo Inc. Hiyllo Work.
      </Typography.Paragraph>
      <Typography.Paragraph>
        The Hiyllo logo, Hiyllo Work logo, Hiyllo Solo logo, and the Nymbl mark
        are copyrighted property of Hiyllo Inc.
      </Typography.Paragraph>
      <Typography.Paragraph>
        No property of Hiyllo may be used without express written permission.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Hiyllo exists thanks to the work of many open source projects.{" "}
        <a href="/licenses.txt">Click here for all licenses</a>.
      </Typography.Paragraph>
    </AboutContainer>
  );
});

const ChatRedirect = React.memo(function ChatRedirect(): JSX.Element {
  const { chatUUID, messageUUID } = useParams();

  return (
    <Navigate
      to={`/v/chat?chatUUID="${chatUUID}"&messageUUID="${messageUUID}"`}
    />
  );
});

export const Router = React.memo(function Router(): React.ReactElement {
  console.debug("Rendering <Router>");

  const config = useConfig();
  const self = useSelfMaybe();
  const isAuthenticated = self != null;

  const isSolo = useIsSolo();

  if ((!config.isConfigured && self?.isAdmin === true && !isSolo)) {
    return <RouterComponent>
      <RouterProviderV1>
        <AdminSetup />
      </RouterProviderV1>
    </RouterComponent>;
  }

  return (
    <PageContainer>
      <RouterComponent>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <TokyoRoot />
              ) : isSolo && !Electron.isElectron ? (
                <SoloLanding />
              ) : config?.staticHomePage?.fileId != null &&
                window.location.hostname !== "localhost" &&
                !isDesktopApp() ? (
                <iframe
                  src={getRootURL() + "/_/omni/static"}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/meet/:meetingUUID/:meetingPassword"
            element={<MeetingExternal />}
          />
          <Route
            path="/m/s/:token"
            element={<SpectatorExternal />}
          />
          <Route
            path="/calendar/rsvp/:eventUUID/:externalActionToken/:rsvp"
            element={<RSVPExternal />}
          />
          <Route path="/wardrobe" element={<WardrobeTest />} />
          <Route
            path="/reset-password/:code"
            element={<CompleteResetPassword />}
          />
          <Route
            path="/v/:feature"
            element={isAuthenticated ? <TokyoRoot /> : <Navigate to="/login" />}
          />

          <Route
            path="/maptest"
            element={isAuthenticated ? <MapTest /> : <Navigate to="/login" />}
          />
          <Route
            path="/admn/gfv-u/:userId"
            element={
              isAuthenticated ? <UserGodView /> : <Navigate to="/login" />
            }
          />
          <Route path="/about" element={<About />} />
          <Route
            path="/integrate/microsoft"
            element={
              isAuthenticated ? (
                <CompleteMicrosoftIntegration />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/integrate/google"
            element={
              isAuthenticated ? (
                <CompleteGoogleIntegration />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/form/:uuid" element={<SubmitForm />} />
          <Route
            path="/admin/:tab?"
            element={
              isAuthenticated ? <AdminPanel /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/login"
            element={isAuthenticated ? <Navigate to="/" /> : <LoginRouter />}
          />
          <Route
            path="/ch/:chatUUID/at/:messageUUID"
            element={isAuthenticated ? <ChatRedirect /> : <LoginRouter />}
          />
          <Route
            path="/oauth/authorize/:appId"
            element={isAuthenticated ? <OAuthAuthorize /> : <LoginRouter />}
          />
          <Route
            path="/oauth-return/hiyllowork/:token"
            element={
              isAuthenticated ? (
                <HiylloWorkOAuthConfirmation />
              ) : (
                <LoginRouter />
              )
            }
          />
          <Route
            path="/integrations/hiyllo-work"
            element={isAuthenticated ? <HiylloWorkPreLink /> : <LoginRouter />}
          />
          <Route
            path="/native-login"
            element={isAuthenticated ? <NativeLogin /> : <LoginRouter />}
          />
          <Route
            path="/native-login-v2"
            element={isAuthenticated ? <NativeLogin v2 /> : <LoginRouter />}
          />
          <Route path="/da" element={<DownloadApp />} />
          <Route path="/download" element={<DownloadApp />} />
          <Route
            path="/sso"
            element={isAuthenticated ? <Navigate to="/" /> : <SSO />}
          />
          <Route
            path="/iauth"
            element={isAuthenticated ? <Navigate to="/" /> : <SSO />}
          />
          <Route path="/b/:token" element={<BookATimeScheduleView />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/electron-login/:token" element={<ElectronLogin />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/settings/integrations"
            element={
              isAuthenticated ? (
                <Padded>
                  <IntegrationsView />
                </Padded>
              ) : (
                <LoginRouter />
              )
            }
          />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </RouterComponent>
    </PageContainer>
  );
});
