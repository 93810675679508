/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export enum BuiltInFolderEnum {
  inbox = "inbox",
  drafts = "drafts",
  sent = "sent",
  spam = "spam",
  pinned = "pinned",
}
