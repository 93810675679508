/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TaskStatusV3 } from "../../../types/tasks/tasks-organization";

export type ParamsType = {
  projectUUID: string;
  statuses: TaskStatusV3[];
};
export type ResponseType = void;
export const Endpoint = 'tasks/projects/update-project-statuses';
export const Method = 'POST';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 1,
  per: 1000,
};export const paramsSchema = {"type":"object","properties":{"projectUUID":{"type":"string"},"statuses":{"type":"array","items":{"$ref":"#/definitions/TaskStatusV3"}}},"required":["projectUUID","statuses"],"definitions":{"TaskStatusV3":{"type":"object","properties":{"uuid":{"type":"string"},"label":{"type":"string"},"color":{"$ref":"#/definitions/TaskColorsEnum"},"projectUUID":{"description":"Project this status is for (null for global, undefined for Hiyllo defaults)","type":["null","string"]},"default":{"type":"boolean"},"closed":{"description":"If the task should be completed \"closed\" when this status is set","type":"boolean"}},"required":["color","label","uuid"]},"TaskColorsEnum":{"enum":["amber","aqua","blue","gray","green","red"],"type":"string"}},"$schema":"http://json-schema.org/draft-07/schema#"}