/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import type { ListTasksSlimTaskType, TaskSizingType } from '../../types/tasks/task-item';
import { TaskStatusV3 } from '../../types/tasks/tasks-organization';

/**
 * 8 work-hours = 1 work-day
 * 5 work-days = 1 work-week
 * 4 work-weeks = 1 work-month
 */

const WORK_HOURS_PER_DAY = 8;
const WORK_DAYS_PER_WEEK = 5;
const WORK_WEEKS_PER_MONTH = 4;

export function getSizingInWorkDays(sizing: TaskSizingType | null): number {
  if (sizing == null) {
    return 0;
  }

  switch (sizing.in) {
    case 'points':
      return 0;
    case 'work-hours':
      return sizing.value / WORK_HOURS_PER_DAY;
    case 'work-days':
      return sizing.value;
    case 'work-weeks':
      return Math.round(sizing.value * WORK_DAYS_PER_WEEK);
    case 'work-months':
      return Math.round(sizing.value * WORK_DAYS_PER_WEEK * WORK_WEEKS_PER_MONTH);
  }
}

export function getTotalSizingInDays(tasks: Array<{ sizing: TaskSizingType | null }>): number {
  return tasks.map((t) => getSizingInWorkDays(t.sizing)).reduce((a, b) => a + b, 0);
}

export function getRemainingSizingInDays(tasks: Array<{ sizing: TaskSizingType | null, remainingSize: TaskSizingType | null, status: TaskStatusV3 }>): number {
  return tasks.filter(t => t.status.closed !== true).map((t) => getSizingInWorkDays(t.remainingSize == null ? t.sizing : t.remainingSize)).reduce((a, b) => a + b, 0);
}

export function getAutomaticEstimateFromWorkDays(days: number): TaskSizingType {
  const workWeeks = days / WORK_DAYS_PER_WEEK;

  return days < 1
    ? {
      value: Math.ceil(days * WORK_HOURS_PER_DAY),
      in: 'work-hours',
      type: 'automatic'
    }
    : days < 14
      ? {
        value: Math.ceil(days),
        in: 'work-days',
        type: 'automatic'
      }
      : workWeeks < 16
        ? {
          value: Math.ceil(workWeeks),
          in: 'work-weeks',
          type: 'automatic'
        }
        : {
          value: Math.ceil(workWeeks / WORK_WEEKS_PER_MONTH),
          in: 'work-months',
          type: 'automatic'
        };
}

export function formatEstimateFromWorkDays(days: number): string {
  const workWeeks = days / WORK_DAYS_PER_WEEK;
  return days < 1 ? `${Math.ceil(days * WORK_HOURS_PER_DAY)} work-hours` : days < 14 ? `${Math.ceil(days)} work-days` : workWeeks < 16 ? `${Math.ceil(workWeeks)} work-weeks` : `${Math.ceil(workWeeks / WORK_WEEKS_PER_MONTH)} work-months`;
}
