import escapeHtml from 'escape-html';
import { Descendant, Text } from 'slate';

export const serializeSlateToHTMLNode = (node: any) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    // @ts-expect-error ---
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    return string;
  }

  // @ts-expect-error ---
  const children = node.children.map(n => serializeSlateToHTMLNode(n)).join('');

  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`;
    case 'paragraph':
      return `<div>${typeof children === "string" && children.trim().length === 0 ? "<br>" : children}</div>`;
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    default:
      return children;
  }
};

export function serializeSlateToHTML(doc: Descendant[]) {
  return doc.map(node => serializeSlateToHTMLNode(node)).join('').replace(/\n/g, '<br>');
}

import html2Pdf from 'html2pdf.js';

export async function exportToPDF(node: HTMLDivElement | string, filename: string): Promise<Blob> {
  return await html2Pdf().set({
    margin: 1,
    filename,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    pagebreak: { mode: ['avoid-all', 'css'] }
  }).from(node).output("blob");
}