import { EJSONCacheReact } from "@hiyllo/ejson-cache-react";
import SimpleIDB from "./local-persistence/simple-idb";
import React from "react";

const dbCache: Record<string, SimpleIDB> = {};

function determineIDBNamespace(key: string): string {
  const slashIndex = key.indexOf("/");
  const dotIndex = key.indexOf(".");
  if (slashIndex !== -1) {
    return key.substring(0, slashIndex);
  }
  if (dotIndex !== -1) {
    return key.substring(0, dotIndex);
  }
  return "omni.default";
}

function getIDB(key: string): SimpleIDB {
  const namespace = determineIDBNamespace(key);
  if (dbCache[namespace] == null) {
    dbCache[namespace] = new SimpleIDB(namespace);
  }
  return dbCache[namespace];
}

export const Cache = new EJSONCacheReact({
  getItem: async (key) => {
    const db = getIDB(key);
    const res = await db.read(key);
    return res?.data ?? null;
  },
  setItem: async (key, value) => {
    const db = getIDB(key);
    await db.write({
      id: key,
      data: value,
    });
  },
});

export function useCacheValue<T>(key: string): T | null {
  const [d, s] = React.useState<T | null>(null);

  React.useEffect(() => {
    void Cache.get<T>(key).then(s);
  }, [key]);

  return d;
}

export function useCacheing<T>(key: string, liveValue: T | null): T | null {
  const [d, s] = React.useState<T | null>(null);

  React.useEffect(() => {
    void Cache.get<T>(key).then(s);
  }, [key]);

  React.useEffect(() => {
    if (liveValue != null) {
      void Cache.set(key, liveValue);
      s(liveValue);
    }
  }, [key, liveValue]);

  return d;
}
