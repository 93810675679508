import React from "react";
import { useProjectTasks } from "../hooks/use-project-tasks";
import { useProjectOrder } from "../hooks/use-project-order";
import {
  type ListTasksSlimTaskType,
} from "../../../types/tasks/task-item";
import { TasksView } from "./tasks-view";
import { HideTaskProjectLabelsCtx } from "../components/task-card";
import { ErrorSplash } from "../../../ux/error-splash";
import { TabDetails } from "../../tokyo/tabbing/tabs-provider";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";

export const TaskProjectView = React.memo(function TaskGroupView(props: {
  projectUUID: string;
  columns?: string[] | null;
  hideHeader?: boolean;
  onClickTask?: (task: ListTasksSlimTaskType) => boolean;
}): JSX.Element {
  const tasks = useProjectTasks({ projectUUID: props.projectUUID });
  const { order, project, onChangeOrder, error } = useProjectOrder({
    projectUUID: props.projectUUID,
    onTaskUpdated: (task) => {
      tasks.ingest([task], false);
    },
  });

  if (error !== null) {
    return (
      <ErrorSplash
        error={error}
        accessDeniedHint="You aren't a member of any of the teams with access to this project"
      />
    );
  }

  if (order === null) {
    return (
      <LoadingSpinnerFullView />
    );
  }

  return (
    <TabDetails label={project != null ? project.name : "Project"}>
      <HideTaskProjectLabelsCtx.Provider value={true}>
        <TasksView
          order={order}
          onChangeOrder={onChangeOrder}
          tasks={tasks.data}
          columns={props.columns}
          hideHeader={props.hideHeader}
          onClickTask={props.onClickTask}
          isReady={!tasks.loading}
          projectUUID={props.projectUUID}
          cacheKey={props.projectUUID}
        />
      </HideTaskProjectLabelsCtx.Provider>
    </TabDetails>
  );
});
