import moment from "moment";
import React from "react";

export function useRealtimeMinuteSynced(): Date {
  const [d, s] = React.useState(new Date());

  React.useEffect(() => {
    let timeout: number | null = null;

    const createTimeout = () => {
      const endOfMinute = moment().endOf("minute").toDate();
      const msTillEndOfMinute = endOfMinute.getTime() - new Date().getTime();

      setTimeout(() => {
        s(new Date());
        createTimeout();
      }, msTillEndOfMinute + 250);
    };

    createTimeout();

    return () => {
      if (timeout != null) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return d;
}
