import React from "react";

export const BaseLoadingBar = React.memo(
  function BaseLoadingBar(): JSX.Element {
    return (
      <div
        style={{
          width: "100%",
          height: 2,
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "100%",
            borderRadius: "inherit",
          }}
          className="LoadingBar"
        />
      </div>
    );
  },
);