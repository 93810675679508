import { Editor, Text, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

function scrollToMatch(editor: Editor) {
  // @ts-expect-error ---
  const domRange = ReactEditor.toDOMRange(editor as ReactEditor, editor.selection);

  if (domRange) {
    const element = domRange.startContainer.parentElement;
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }
}

export function findAndReplace(editor: Editor, query: string, replace: string | null = null, replaceAll: boolean = false) {
  // Remove all existing highlights
  Transforms.setNodes(
    editor,
    // @ts-expect-error ---
    { find_highlight: undefined }, // Reset the custom `find_highlight` property
    // @ts-expect-error ---
    { at: [], match: (node) => Text.isText(node) && node.find_highlight, split: true }
  );

  if (!query || typeof query !== 'string') {
    console.log('Query is empty; highlights removed.');
    return; // If query is empty, just remove highlights and exit
  }

  const matches = [];

  // Iterate through all text nodes to find matches
  for (const [node, path] of Editor.nodes(editor, { at: [], match: Text.isText })) {
    const { text } = node;
    let index = text.toLowerCase().indexOf(query.toLowerCase());

    while (index !== -1) {
      matches.push({ path, index, length: query.length });
      index = text.indexOf(query, index + query.length);
      if (!replaceAll) break; // Stop after the first match if not replacing all
    }

    if (!replaceAll && matches.length) break;
  }

  if (!matches.length) {
    console.log('No matches found.');
    return;
  }

  if (replace !== null) {
    // Replace the found matches
    for (const match of matches) {
      const { path, index, length } = match;
      Transforms.select(editor, { anchor: { path, offset: index }, focus: { path, offset: index + length } });
      Transforms.insertText(editor, replace);

      // Scroll into view after replacement
      scrollToMatch(editor);

      if (!replaceAll) break;
    }
  } else {
    // Highlight matches using custom marks
    matches.forEach(({ path, index, length }, i) => {
      Transforms.setNodes(
        editor,
        // @ts-expect-error ---
        { find_highlight: true }, // Custom property
        { at: { anchor: { path, offset: index }, focus: { path, offset: index + length } }, match: Text.isText, split: true }
      );

      // Scroll the first match into view
      if (i === 0) {
        Transforms.select(editor, { anchor: { path, offset: index }, focus: { path, offset: index + length } });
        scrollToMatch(editor);
      }
    });
  }
}
